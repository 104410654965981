import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Page, Button, Title, ValidatedInput, Spinner } from '../../components'
import { LiteralsService } from '../../services'
import { IStoreState } from '../../store/states'
import { images } from '../../images'
import theme from '../../theme'
import {
  getSettingsAction,
  setSettingsRewardAddressAction,
  setSettingsThresholdAction,
  setSettingsUsernameAction,
  updateSettingsAction
} from '../../store/actions'
import StyledArticle from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  username: string
  rewardAddress: string
  threshold: number
  error: boolean
  isLoading: boolean
  isUpdating: boolean
}

class Settings extends PureComponent<IProps> {

  public componentDidMount() {
    this.props.dispatch(getSettingsAction())
  }

  private get saveChangesButton(): ReactNode {
    const { isUpdating } = this.props
    // if (isUpdating) return <Spinner />
    return (
      <Button
        title={ LiteralsService.get('saveChanges') }
        onPress={ this.onSaveChanges }
        filled
        upper
        loading={isUpdating}
        styles={{
          marginTop: '2rem',
          width: '160px',
          height: '40px',
          fontSize: '16px',
          color: theme.colors.white,
          backgroundColor: theme.colors.azure,
          alignSelf: 'flex-end'
        }}
      />
    )
  }

  public render(): ReactNode {
    const { username, rewardAddress, threshold, isLoading } = this.props
    return (
      <Page style={{flex: 1}}>
        <StyledArticle>
          <header>
            <img src={images.iconUserGreen} />
            <h3>{LiteralsService.get('profile', true)}</h3>
          </header>
          {
            isLoading ?
            <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
              <Spinner />
            </div>
            :
            <form onSubmit={ e => e.preventDefault() }>
              <Title text={ LiteralsService.get('accountDetails', true) } />
              <section>
                <ValidatedInput
                  name={'username'}
                  label={ LiteralsService.get('username', true) }
                  labelColor={theme.colors.white}
                  value={ username }
                  onChange={ this.onUsernameChange }
                  styles={{
                  }}
                />
                <ValidatedInput
                  name={'address'}
                  label={ LiteralsService.get('xchRewardAddress', true) }
                  labelColor={theme.colors.white}
                  value={ rewardAddress }
                  onChange={ this.onTokenChange }
                  styles={{
                  }}
                />
                <ValidatedInput
                  name={'payout'}
                  label={ LiteralsService.get('minimumPayoutTreshold', true) }
                  labelColor={theme.colors.white}
                  value={ threshold.toString() }
                  onChange={ this.onPayout }
                  styles={{
                  }}
                />
              </section>
              <section className="actions">
                { this.saveChangesButton }
              </section>
            </form>
          }
        </StyledArticle>
      </Page>
    )
  }

  private onUsernameChange = (v: any) => {
    this.setState({username: v})
    this.props.dispatch(setSettingsUsernameAction(v))
  }

  private onTokenChange = (v: any) => {
    this.setState({token: v})
    this.props.dispatch(setSettingsRewardAddressAction(v))
  }

  private onPayout = (v: any) => {
    this.setState({payout: v})
    this.props.dispatch(setSettingsThresholdAction(v))
  }

  private onSaveChanges = (): void => {
    const { dispatch } = this.props
    dispatch(updateSettingsAction())
  }

}

const mapStateToProps = ({ setting }: IStoreState) => ({
  username: setting.username,
  rewardAddress: setting.rewardAddress,
  threshold: setting.threshold,
  error: setting.error,
  isLoading: setting.isLoading,
  isUpdating: setting.isUpdating
})

export default withRouter(connect(mapStateToProps)(Settings))
