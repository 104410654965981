import { IColors, ITheme } from './models'

const colors: IColors = {
  blue: '#280A54',
  primary: '#E42313',
  blueLight: '#8136DB',
  greenSpring: '#28F19C',
  black: '#252525',
  greyDark: '#58585A',
  grey: '#B7BDCC',
  greyLight: '#E3E6EB',
  greyMedium: '#848EA8',
  almostWhite: '#F3F3F3',
  white: '#FFFFFF',
  green: '#6AAB5E',
  greenText: '#7FE66E',
  almostGreen: '#6DCA7D',
  lightGreen: '#80E76E',
  darkGreen: '#147502',
  orange: '#F5C600',
  orangeDark: '#E77230',
  red: '#D83556',
  purple: '#280A54',
  greenNotification: '#00BBA7',
  tarawera: '#083E5A',
  cerulean: '#05A9F1',
  cloudBurst: '#223149',
  ebonyClay: '#232E3D',
  mirage: '#131C29',
  sail: '#B6D0FA',
  azure: '#029DF5',
  eastBay: '#3E5880',
  orient: '#035477',
  transparent: 'transparent'
}

const theme: ITheme = {
  fontFamily: 'Montserrat',
  fontMyriad: 'MyRiadPro,sans-serif',
  colors,
  textStyles: {
    display: { fontFamily: 'Montserrat', fontWeight: 700, fontSize: 24, color: colors.black },
    title: { fontFamily: 'Montserrat', fontSize: 18, fontWeight: 700, color: colors.black },
    hightlight: {
      fontFamily: 'Montserrat',
      fontSize: 18,
      fontWeight: 600,
      color: colors.black
    },
    body: { fontFamily: 'Fira Sans', fontSize: 18 },
    subTitle: {
      fontFamily: 'Montserrat',
      fontSize: 18,
      fontWeight: 600,
      color: colors.black
    },
    caption: { fontFamily: 'Fira Sans', fontSize: 14, color: colors.black },
    button: { fontFamily: 'Assistant-Bold', fontSize: 16, color: colors.black },
    link: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 600,
      color: colors.primary,
      textDecorationLine: 'underline'
    },
    grey: {
      color: colors.grey
    },
    pickerText: {
      fontFamily: 'Fira Sans',
      fontSize: 18,
      color: colors.black,
      lineHeight: 23
    }
  }
}

export default theme
