import React, { useEffect, useRef } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { showMobileMenuAction, signOutAction } from '../../store/actions'
import { urls } from '../../routing'
import { LiteralsService } from '../../services'
import { IStoreState } from '../../store/states'
import { useOnClickOutsideElement, useViewport } from '../../hooks'
import { Button } from './../../components'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  username: string
  showMobileMenu: boolean
}

const SideMenu: React.FC<IProps> = ({
  dispatch,
  history,
  showMobileMenu
}) => {

  const menuRef = useRef(null)
  const { isClickOutside } = useOnClickOutsideElement(menuRef)

  const { width } = useViewport()
  const breakpoint = 768

  useEffect(() => {
    if (isClickOutside) dispatch(showMobileMenuAction(false))
  }, [dispatch, isClickOutside])

  const onPressHome = () => {
    dispatch(showMobileMenuAction(false))
    history.push(urls.home)
  }

  const onPressDashboard = () => {
    dispatch(showMobileMenuAction(false))
    history.push(urls.dashboard)
  }

  const onPressSettings = () => {
    dispatch(showMobileMenuAction(false))
    history.push(urls.settings)
  }

  const onPressLogout = () => {
    dispatch(showMobileMenuAction(false))
    dispatch(signOutAction())
  }

  const onPressDiscord = () => {
    dispatch(showMobileMenuAction(false))
    window.open('https://discord.gg/27TnmnqChh', '_blank')
  }

  const onPressHowToJoin = () => {
    dispatch(showMobileMenuAction(false))
    window.open('https://supermine.readthedocs.io/en/latest/', '_blank')
  }

  const onPressReportBug = () => {
    dispatch(showMobileMenuAction(false))
    window.open('https://github.com/SuperMine-Pooling/SuperMine-XCH-Client/issues', '_blank')
  }

  const onPressIcebreker = () => {
    dispatch(showMobileMenuAction(false))
    window.open('https://github.com/SuperMine-Pooling/SuperMine-XCH-Client', '_blank')
  }

  return (
    <>
    {
      (width > breakpoint || showMobileMenu) &&
      <StyledSection ref={menuRef}>
        <Button
          icon={'iconHome'}
          title={LiteralsService.get('home')}
          upper
          className={'iconButton'}
          onPress={onPressHome}
          styles={{margin: '2rem 0'}}
        />
        <p className={'title'}>{LiteralsService.get('account')}</p>
        <Button
          icon={'iconDashboard'}
          title={LiteralsService.get('dashboard')}
          upper
          className={'iconButton'}
          onPress={onPressDashboard}
          styles={{margin: '2rem 0 0'}}
        />
        <Button
          icon={'iconSettings'}
          title={LiteralsService.get('settings')}
          upper
          className={'iconButton'}
          onPress={onPressSettings}
          styles={{margin: '1rem 0'}}
        />
        <Button
          icon={'iconLogout'}
          title={LiteralsService.get('logout')}
          upper
          className={'iconButton'}
          onPress={onPressLogout}
          styles={{margin: '0 0 2rem'}}
        />
        <p className={'title'}>{LiteralsService.get('contact')}</p>
        <Button
          icon={'iconDiscordGreen'}
          title={LiteralsService.get('discord')}
          upper
          className={'iconButton'}
          onPress={onPressDiscord}
          styles={{margin: '2rem 0'}}
        />
        <p className={'title'}>{LiteralsService.get('download')}</p>
        <Button
          icon={'iconHowToJoin'}
          title={LiteralsService.get('howToJoinPool')}
          upper
          className={'iconButton'}
          onPress={onPressHowToJoin}
          styles={{margin: '2rem 0 0'}}
        />
        <Button
          icon={'iconBug'}
          title={LiteralsService.get('reportIssue')}
          upper
          className={'iconButton'}
          onPress={onPressReportBug}
          styles={{margin: '1rem 0 0'}}
        />
        <Button
          icon={'iconDownloadingFile'}
          title={LiteralsService.get('iceBreaker')}
          upper
          className={'iconButton'}
          onPress={onPressIcebreker}
          styles={{margin: '1rem 0'}}
        />
      </StyledSection>
    }
    </>
  )
}

const mapStateToProps = ({ userInfo, dashboard }: IStoreState) => ({
  username: userInfo.username,
  showMobileMenu: dashboard.showMobileMenu
})

export default withRouter(connect(mapStateToProps)(SideMenu))
