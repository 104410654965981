import { useEffect, useState } from 'react'

export const useOnClickOutsideElement = (ref: any) => {
  const [isClickOutside, setIsClickOutside] = useState(false)

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsClickOutside(true)
      } else {
        setIsClickOutside(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
        // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
}, [ref])

  // Return boolean value of click outside
  return { isClickOutside }
}
