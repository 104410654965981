/* eslint-disable camelcase */
/* eslint-disable max-len */

export const literals = {
  en: {
    supermine: 'PolarMine',
    name: 'name',
    username: 'username',
    usernameOrEmail: 'username / email',
    email: 'email',
    password: 'password',
    confirmPassword: 'confirm password',
    forgottenPassword: 'Forgot your password ?',
    dontHaveAccount: 'don\'t have an account ?',
    alreadyHaveAccount: 'already have an account ?',
    dashboard: 'dashboard',
    marketPlace: 'marketplace',
    chiaXch: 'chia | xch',
    comingSoon: 'coming soon',
    about: 'about',
    whitePaper: 'white paper',
    team: 'team',
    contactUs: 'Contact us',
    roadMap: 'Road map',
    login: 'login',
    register: 'register',
    poolingWorld: 'Pooling for the Decentralized World',
    secure: 'Secure,',
    scalable: 'scalable',
    profitable: 'profitable',
    joinNow: 'Join Now!',
    joinThePool: 'Join the pool',
    xchPoolStats: 'XCH Pool Stats',
    activeFarmers: 'Active Farmers:',
    xchPerWeek: 'XCH Per Week',
    xchPerMonth: 'XCH Per Month',
    pooledPlotSpace: 'Your Pooled Plot Space',
    totalSizeofPlots: 'Total Size of Plots:',
    estimatedTimetoWin: 'Estimated Time to Win',
    day: 'day',
    fullNodeStatus: 'Full Node Status:',
    synced: 'Synced',
    chiaNetSpace: 'Chia net space:',
    pib: 'pib',
    netSpaceInPool: 'Netspace in Pool',
    totalPlots : 'Total Plots in Pool',
    tibDay: 'TIB day',
    services: 'services',
    fasterPlotting: 'Faster Plotting',
    fasterPlottingDescription1: 'With our custom super plotter\nYou can create your Chia Plots faster and start Farming chia from the pool sooner',
    fasterPlottingDescription2: 'Our Plotter reduces the writes on your Drive so basically your drives lasts longer',
    fullyDecentralized: 'Fully Decentralized',
    fullyDecentralizedDescription1: 'Nobody owns your private keys and never will\nUsers have full control of the rewards they receive from the pool',
    fullyDecentralizedDescription2: 'With our expansion we will introduce fully decentralised smart contract based pooling for crypto to give users full control of their mining power and rewards',
    plotMarketplace: 'Plot Marketplace',
    plotMarketplaceDescription1: 'The PolarMine Marketplace will allow for plots to be bought and sold through a permissionless escrow service',
    plotMarketplaceDescription2: 'Main currency of the marketplace will be XCH at the moment and more will be added to setup easy exchange of Chia ecosystem assets',
    aboutUs: 'About us',
    aboutUsDescription: 'The Chia Netspace grew almost 100% week over week throughout May 2021. This means that farmers will have to add capacity faster to earn the same rewards. With PolarMine, you get all the tools you need to keep up with the competition.',
    contactUsQuestions: 'contact us with any questions at:',
    mainFeatures: 'Main Features',
    unofficialChiaPoolingProtocal: 'Unofficial Chia Pooling Protocol',
    linuxAndWindowsSupport: 'Linux and Windows support',
    dashboardToViewBlockRewards: 'Dashboard to view block rewards',
    officialChiaPoolingProtocal: 'Official Chia Pooling Protocal',
    poolServerStats: 'Pool Server Stats',
    scalableFalutTolerantSecure: 'Scalable, Falut-tolerant, Secure',
    stabiltyBugfixesOfOurPlatform: 'Stability/Bug fixes of our platform',
    buildingOurEcosystem: 'Building our Ecosystem',
    leaderboard: 'Leaderboard',
    referralSystem: 'Referral system',
    multipleLanugageSupport: 'Multiple Language support',
    multipleRegion: 'Multiple Region',
    communityVoting: 'Community voting',
    darkMode: 'Dark mode',
    farmerMarketplace: 'Farmer Marketplace Beta',
    putYourXchRewardsToUse: 'Put your XCH rewards to use',
    community: 'Community',
    communittyList: 'Community listing',
    votingImprovement: 'Voting Improvement',
    newCoinListing: 'New Coin Listing',
    proDashboard: 'Pro Dashboard',
    rewards: 'Rewards',
    airdropPlatformForOurUsers: 'Airdrop Platform for our Users',
    educationPlatform: 'Education Platform',
    partnershipsWithotherEcosystem: 'Partnerships with other ecosystem',
    future: 'Future',
    introductionToNew: 'Introduction to new',
    roadmapPlanAndExpansion: 'Roadmap Plan and expansion',
    defiDiscovery: 'Defi Discovery',
    nftDiscovery: 'NFT Discovery',
    language: 'Language',
    settings: 'Settings',
    logout: 'Logout',
    home: 'Home',
    discord: 'Discord',
    howToJoin: 'How to join',
    howToJoinPool: 'How to join the Pool',
    iceBreaker: 'Ice Breaker',
    account: 'Account',
    contact: 'Contact',
    download: 'Download',
    lifetimeBalance: 'Lifetime Balance',
    walletBalance: 'Wallet Balance',
    farmersInPool: 'Farmers in Pool',
    xchUsdPrice: 'XCH USD Price',
    totalDrives: 'Total Drives',
    networkSize: 'Network Size',
    expectedTimeToWin: 'Expected Time to Win',
    expectedDailyEarnings: 'Expected Daily Earnings',
    expectedWeeklyEarnings: 'Expected Weekly Earnings',
    storageTotal: 'Storage Total',
    expectedMonthlyEarnings: 'Expected Monthly Earnings',
    expectedYearlyEarnings: 'Expected Yearly Earnings',
    longestChallenges: 'Longest Response',
    missedChallenges: 'Missed Challenges',
    completeSubSlots: 'Complete Sub Slots',
    skippedBlocks: 'Skipped Blocks',
    connectedNodes: 'Connected Nodes',
    farmers: 'Farmers',
    filter: 'Filter',
    online: 'Online',
    offline: 'Offline',
    token: 'Token',
    plotCount: 'Plot Count',
    status: 'Status',
    profile: 'Profile',
    accountDetails: 'Account Details',
    xchRewardAddress: 'XCH Reward Address (Change if you want)',
    minimumPayoutTreshold: 'Minimum payout treshold (Needs to be above 0.05 XCH)',
    saveChanges: 'Save Changes',
    success: 'Success',
    incorrectUsernameAndPassword: 'Incorrect username/password',
    reuqestFailed: 'Request Failed, Try again later',
    ourPlatform: 'Our Platform',
    iceBreakerTitle: 'Ice Breaker V1',
    iceBreakerDescription1: 'Our client is built from the ground up in-house to be secure and efficient.',
    iceBreakerDescription2: 'Farmers will be rewarded equally for the space they contribute to the pool.',
    iceBreakerDescription3: 'Supported Operating Systems:\n\nUbuntu/Debian\nWindows\nMac',
    userDashboardTitle: 'User Dashboard',
    userDashboardDescription1: 'From the \'Dashboard\' page, users can visualize their daily XCH earnings against the growing Netspace.',
    userDashboardDescription2: 'Give each of your farmer tokens a custom name in order to easily identify each farmer.',
    userDashboardDescription3: 'Customize your user settings in order to change your reward address or auto payout threshold.',
    copyright: 'Copyright © 2021',
    documentation: 'Document',
    lastUpdate: 'Last Update',
    netSpace: 'Net space',
    pendingBalance: 'Pending Balance',
    xchPerDay: 'XCH Per Day',
    createFarmerToken: 'Create Farmer Token',
    copy: 'Copy',
    copied: 'Copied',
    reportIssue: 'Report a bug',
    transparent: 'Transparent',
    and: 'and',
    communityDriven: 'Community-Driven',
    unofficialChiaPoolingProtocolDescription: '&nbsp&nbsp&nbsp&nbsp- Linux, Windows, and Mac OSX support\n&nbsp&nbsp&nbsp&nbsp- Dashboard to view points and rewards\n&nbsp&nbsp&nbsp&nbsp- Multiple Language Support\n&nbsp&nbsp&nbsp&nbsp- Referral System\n&nbsp&nbsp&nbsp&nbsp- Leaderboard\n&nbsp&nbsp&nbsp&nbsp- Pool server stats\n&nbsp&nbsp&nbsp&nbsp- Scale platform to meet user demand\n&nbsp&nbsp&nbsp&nbsp- Fault-tolerance in case of outages\n&nbsp&nbsp&nbsp&nbsp- Increase network security\n&nbsp&nbsp&nbsp&nbsp- Stability/bug fixes for our platform',
    supportedProtocols: 'Supported Protocols',
    registrationCurrentlyClosed: '*Registration is currently closed. Join our Discord for more info.',
    changeTokenName: 'Change Token Name',
    change: 'Change',
    lastFarmedBlock: 'Last Farmed Block',
    xchPrice: 'XCH Price in USD',
    poolCapacity: 'Pool Capacity',
    totalNetspace: 'Total Netspace',
    usdPerDay :'USD Per Day',
    usdPerWeek :'USD Per Week',
    usdPerMonth :'USD Per Month',
    totalSpaceInPool : 'Total Pooled Space',
    xchExpectedEarningNextBlock: 'Exp. XCH Earnings Next Block',
    hdd_price_usd: 'HDD Price USD',
    total_netspace: 'Total Netspace',
    total_pooled_space: 'Total Pooled Space',
    number_of_farmers: 'Number of Farmers',
    pending_balance: 'Pending Balance',
    lifetime_balance: 'Lifetime Balance',
    expected_earnings_next_block: 'Exp. Earnings Next Block',
    your_pooled_plot_space: 'Your Pooled Plot Space',
    hdd_per_day: 'HDD Per Day',
    hdd_per_week: 'HDD Per Week',
    hdd_per_month: 'HDD Per Month',
    usd_per_day: 'USD Per Day',
    usd_per_week: 'USD Per Week',
    usd_per_month: 'USD Per Month',
    xch_price_usd: 'XCH Price USD',
    xch_per_day: 'XCH Per Day',
    xch_per_week: 'XCH Per Week',
    xch_per_month: 'XCH Per Month'
  }
}
