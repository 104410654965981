import React from 'react'
import { images } from '../../../images'
import theme from '../../../theme'
import { IMenuOption } from '../../../models'
import StyledLi from './styles'

interface IProps {
  option: IMenuOption
  isCheck?: boolean
  selected?: boolean
  onClick?: () => void
}

export const Option: React.FC<IProps> = ({ option, isCheck = false, selected, onClick }) => {

  const handleClick = () => {
    if (option.action && typeof option.action === 'function' ) option.action()
    if (onClick && typeof onClick === 'function') onClick()
  }

  return (
    <StyledLi
      className={ option.isDisabled ? 'disabled' : '' }
      onClick={ handleClick }
      backgroundColor={option.backgroundColor ? option.backgroundColor : theme.colors.white}
      color={option.color}
      activeColor={option.activeColor}
    >
      {
        option.icon &&
        <img src={ images[option.icon] } alt={ option.label } />
      }
      {
        isCheck &&
        <div className='checkmark'>
          {
            selected &&
            <img src={images.iconCheck} />
          }
        </div>
      }
      <span>{ option.label }</span>
    </StyledLi>
  )
}
