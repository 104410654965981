import {
  IClearSignUpFieldsAction,
  ISetIsSigningUpAction,
  ISetSignUpEmailAction,
  ISetSignUpUsernameAction,
  ISetSignUpNameAction,
  ISetSignUpPasswordAction,
  ISetSignUpTermsAndConditionsAction,
  ISignUpAction,
  ISignUpFailedAction,
  ISignUpSuccessfulAction,
  SignUpTypeKeys,
  IOpenSignUpPopupAction
} from '../types'

export function clearSignUpFieldsAction(): IClearSignUpFieldsAction {
  return { type: SignUpTypeKeys.CLEAR_SIGN_UP_FIELDS }
}

export function openSignUpPopupAction(payload: boolean): IOpenSignUpPopupAction {
  return { type: SignUpTypeKeys.OPEN_SIGN_UP_POPUP, payload: payload }
}

export function setSignUpEmailAction(email: string): ISetSignUpEmailAction {
  return { type: SignUpTypeKeys.SET_SIGN_UP_EMAIL, payload: email }
}

export function setSignUpPasswordAction(password: string): ISetSignUpPasswordAction {
  return { type: SignUpTypeKeys.SET_SIGN_UP_PASSWORD, payload: password }
}

export function setSignUpNameAction(name: string): ISetSignUpNameAction {
  return { type: SignUpTypeKeys.SET_SIGN_UP_NAME, payload: name }
}

export function setSignUpUsernameAction(username: string): ISetSignUpUsernameAction {
  return { type: SignUpTypeKeys.SET_SIGN_UP_USERNAME, payload: username }
}

export function setSignUpTermsAndConditionsAction(): ISetSignUpTermsAndConditionsAction {
  return { type: SignUpTypeKeys.SET_SIGN_UP_TERMS }
}

export function signUpAction(): ISignUpAction {
  return { type: SignUpTypeKeys.SIGN_UP }
}

export function setIsSigningUpAction(): ISetIsSigningUpAction {
  return { type: SignUpTypeKeys.SIGNING_UP }
}

export function signUpSuccessfulAction(): ISignUpSuccessfulAction {
  return { type: SignUpTypeKeys.SIGN_UP_SUCCESSFUL }
}

export function signUpFailedAction(payload: any): ISignUpFailedAction {
  return { type: SignUpTypeKeys.SIGN_UP_FAILED, payload }
}
