import { all } from 'redux-saga/effects'
import userInfoSagas from './user-info'
import signInSagas from './sign-in'
import settingSagas from './setting'
import dashboardSagas from './dashboard'

export default function* rootSaga() {
  yield all([
    userInfoSagas(),
    signInSagas(),
    settingSagas(),
    dashboardSagas()
  ])
  // yield all(sagas.map((saga: any) => fork(saga, context)))
}
