import React, { useEffect } from 'react'
import { RouteProps } from 'react-router-dom'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { LiteralsService } from '../../../services'
import { images } from '../../../images'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteProps {

}

const Welcome: React.FC<IProps> = () => {
  const animation = useAnimation()
  const [refInView, inView] = useInView({ threshold: 0.3 })

  const variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.3, delayChilden: 0.5, staggerChildren: 0.3 }
    },
    hidden: {
      y: 0,
      opacity: 0
    }
  }

  useEffect(() => {
    if (inView) {
      animation.start('visible')
    } else {
      // animation.start('hidden')
    }
  }, [inView, animation])

  return(
    <StyledSection>
      <motion.div ref={refInView} animate={animation} initial={'hidden'} variants={variants}>
        <motion.h3
          variants={variants}
        >
          {LiteralsService.get('poolingWorld')}
        </motion.h3>
        <p>
          <motion.span
            variants={variants}
          >
            {LiteralsService.get('scalable') + ', '}
          </motion.span>
          <motion.span
            variants={variants}
          >
            {LiteralsService.get('secure') + ' '}
          </motion.span>
          <motion.span
            variants={variants}
          >
            {LiteralsService.get('and') + ' '}
          </motion.span>
          <motion.span
            variants={variants}
          >
            {LiteralsService.get('communityDriven')}
          </motion.span>
        </p>
        <motion.p className="supported-protocol" variants={variants}>
          {LiteralsService.get('supportedProtocols').toUpperCase()}
        </motion.p>
        <motion.p className="protocol-icon" variants={variants}>
          <img src={images.logoChia} />
        </motion.p>
        <motion.p className="protocol-icon" variants={variants}>
          <img src={images.hddLogo} />
          <span>{'* ' + LiteralsService.get('comingSoon')}</span>
        </motion.p>
        <motion.button
          variants={variants}
          onClick={() => {window.open('https://discord.gg/27TnmnqChh', '_blank')}}
        >
          {LiteralsService.get('joinThePool')}
        </motion.button>
        <motion.p className="registration-disabled" variants={variants}>
          {LiteralsService.get('registrationCurrentlyClosed')}
        </motion.p>
      </motion.div>
    </StyledSection>
  )
}


export default connect()(Welcome)
