import styled from 'styled-components'

export default styled.section`
  position: relative;
  display: flex;
  flex-direction: column;

  margin: 33px 10px 0 10px;
  background-color: ${ ({ theme }) => theme.colors.cloudBurst };
  border-radius: 8px;
  padding: 12px 14px 26px;
  overflow-y: auto;

  @media screen and (min-width:70rem){
      margin: 33px 60px 0 125px;
  }

  header {
    margin: 8px 0 0 0 !important;
    justify-content: space-between;

    img {
      width: 19px !important;
      height: 19px !important;
      cursor: pointer !importan;
    }

    h5 {
      margin: 0 5px 0 0;
      color: ${ ({ theme }) => theme.colors.azure };
      font-family: Montserrat;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
    }

    button {
      &:hover {
        background-color: ${ ({ theme }) => theme.colors.azure }50;
      }
    }
  }
`
