export enum SettingsTypeKeys {
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  GET_SETTINGS = 'GET_SETTINGS',
  GETTING_SETTINGS = 'GETTING_SETTINGS',
  GET_SETTINGS_SUCCESSFUL = 'GET_SETTINGS_SUCCESSFUL',
  SET_SETTINGS_USERNAME = 'SET_SIGN_IN_USERNAME',
  SET_SETTINGS_REWARD_ADDRESS = 'SET_SETTINGS_REWARD_ADDRESS',
  SET_SETTINGS_THERSHOLD = 'SET_SETTINGS_THRESHOLD',
  UPDATE_SETTINGS = 'UPDATE_SETTINGS',
  UPDATING_SETTINGS = 'UPDATING_SETTINGS',
  UPDATE_SETTINGS_SUCCESSFUL = 'UPDATE_SETTINGS_SUCCESSFUL',
  UPDATE_SETTINGS_FAILED = 'UPDATE_SETTINGS_FAILED',
}

export interface IChangeLanguageAction {
  type: typeof SettingsTypeKeys.CHANGE_LANGUAGE
  payload: string
}

export interface IGetSettingsAction {
  type: typeof SettingsTypeKeys.GET_SETTINGS
}

export interface ISetIsGettingSettingsAction {
  type: typeof SettingsTypeKeys.GETTING_SETTINGS
}

export interface IGetSettingsSuccessfulAction {
  type: typeof SettingsTypeKeys.GET_SETTINGS_SUCCESSFUL
  payload: any
}

export interface ISetSettingsUsernameAction {
  type: typeof SettingsTypeKeys.SET_SETTINGS_USERNAME
  payload: string
}

export interface ISetSettingsRewardAddressAction {
  type: typeof SettingsTypeKeys.SET_SETTINGS_REWARD_ADDRESS
  payload: string
}

export interface ISetSettingsThresholdAction {
  type: typeof SettingsTypeKeys.SET_SETTINGS_THERSHOLD
  payload: number
}

export interface ISetUpdateSettingsAction {
  type: typeof SettingsTypeKeys.UPDATE_SETTINGS
}

export interface ISetIsUpdatingSettingsAction {
  type: typeof SettingsTypeKeys.UPDATING_SETTINGS
}

export interface ISetUpdateSettingsSuccessfulAction {
  type: typeof SettingsTypeKeys.UPDATE_SETTINGS_SUCCESSFUL
}

export interface ISetUpdateSettingsFailedAction {
  type: typeof SettingsTypeKeys.UPDATE_SETTINGS_FAILED
}

export type SettingsActionTypes =
  | IChangeLanguageAction
  | IGetSettingsAction
  | ISetIsGettingSettingsAction
  | IGetSettingsSuccessfulAction
  | ISetSettingsUsernameAction
  | ISetSettingsRewardAddressAction
  | ISetSettingsThresholdAction
  | ISetUpdateSettingsAction
  | ISetIsUpdatingSettingsAction
  | ISetUpdateSettingsSuccessfulAction
  | ISetUpdateSettingsFailedAction
