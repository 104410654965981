/* eslint-disable camelcase */
import { DashboardResponse } from '../../models'
import { DashboardActionTypes, DashboardTypeKeys } from '../types'

export const initialDashboardState = {
  xch: {
    xch_price_usd: 0,
    total_netspace: '',
    total_pooled_space: '',
    number_of_farmers: 0,
    pending_balance: 0,
    lifetime_balance: 0,
    expected_earnings_next_block: 0,
    your_pooled_plot_space: '',
    xch_per_day: 0,
    xch_per_week: 0,
    xch_per_month: 0,
    usd_per_day: 0,
    usd_per_week: 0,
    usd_per_month: 0
  },
  hdd: {
    hdd_price_usd: 0,
    total_netspace: '',
    total_pooled_space: '',
    number_of_farmers: 0,
    pending_balance: 0,
    lifetime_balance: 0,
    expected_earnings_next_block: 0,
    your_pooled_plot_space: '',
    hdd_per_day: 0,
    hdd_per_week: 0,
    hdd_per_month: 0,
    usd_per_day: 0,
    usd_per_week: 0,
    usd_per_month: 0
  },
  farmers: [],
  renameFarmer: {
    name: '',
    token: ''
  },
  isRenamingFarmerToken: false,
  error: false,
  isLoading: false,
  isCreatingFarmerToken: false,
  copiedFarmerToken: '',
  showMobileMenu: false
}

const dashboard = (
  state: DashboardResponse = initialDashboardState,
  action: DashboardActionTypes
) => {
  switch (action.type) {
    case DashboardTypeKeys.SHOW_MOBILE_MENU:
      return { ...state, showMobileMenu: action.payload }
    case DashboardTypeKeys.COPY_FARMER_TOKEN:
      return { ...state, copiedFarmerToken: action.payload }
    case DashboardTypeKeys.CREATING_FARMER_TOKEN:
      return { ...state, isCreatingFarmerToken: action.payload }
    case DashboardTypeKeys.CREATED_FARMER_TOKEN_SUCCESSFULLY:
      return { ...state, isCreatingFarmerToken: false, farmers: [...state.farmers, action.payload] }
    case DashboardTypeKeys.RENAME_FARMER_TOKEN:
      return { ...state, isRenamingFarmerToken: action.payload }
    case DashboardTypeKeys.RENAMEING_FARMER_TOKEN:
      return { ...state, renameFarmer: action.payload }
    case DashboardTypeKeys.RENAME_FARMER_TOKEN_SUCCESSFULLY:
      return { ...state, farmers: [...state.farmers, action.payload] }
    case DashboardTypeKeys.GETTING_DASHBOARD:
      return { ...state, isLoading: action.payload }
    case DashboardTypeKeys.GET_DASHBOARD_SUCCESSFUL:
      return { ...state, isLoading: false, error: false, ...action.payload }
    default:
      return state
  }
}

export default dashboard
