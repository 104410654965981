import { RouteComponentProps } from 'react-router-dom'
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects'
import { urls } from '../../routing'
import {
  signInSuccessfulAction
} from '../actions'
import { IStoreState } from '../states'
import { UserInfoTypeKeys } from '../types'

// Worker Saga
function* checkUserAsync() {
  const routerHistory: RouteComponentProps['history'] = yield getContext('routerHistory')

  const { username, token } = yield select(
    (state: IStoreState) => state.userInfo
  )

  if (token && username) {
    // yield put(setUserInfoUsernameAction(username))
    // yield put(setUserInfoTokenAction(token))
    // yield call(routerHistory.push, urls.dashboard)
  } else {
    yield call(routerHistory.replace, urls.home)
  }

  yield put(signInSuccessfulAction())
}

// Watcher Saga:
function* watchCheckUserAsync() {
  yield takeLatest(UserInfoTypeKeys.CHECK_USER, checkUserAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchCheckUserAsync()])
}
