import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { images } from '../../images'
import { Page } from '../../components'
import { Footer, Roadmap, Services, SocialMenu, Stats, Welcome } from '../../containers'
import { urls } from '../../routing'
import { openSignInPopupAction, openSignUpPopupAction } from '../../store/actions'
import { IStoreState } from '../../store/states'
import { ImagePolar, ImageClouds, ImageMobileBackground } from './styles'

interface Props extends DispatchProp<AnyAction>, RouteComponentProps {
  token: string
}

interface States {
  width: number
  height: number
  offset: number
}

class Home extends PureComponent<Props, States> {

  constructor(props: Props) {
    super(props)
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      offset: Math.round((window.innerWidth - 1300) / 2)
    }
  }

  public async componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    if (this.props.match.url === urls.login) {
      this.props.dispatch(openSignInPopupAction(true))
    } else if (this.props.match.url === urls.register) {
      this.props.dispatch(openSignUpPopupAction(true))
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  private updateDimensions = () => {
    const offset = window.innerWidth > 1280 ? Math.round((window.innerWidth - 1300) / 2) : 0
    this.setState({ width: window.innerWidth, height: window.innerHeight, offset: offset })
  }

  public render(): ReactNode {
    return (
      <Page style={{overflowY: 'hidden'}}>
        <ImageClouds>
          <img src={ images.cloudsWithWater } alt="supermine"/>
        </ImageClouds>
        <ImageMobileBackground>
          <img src={ images.backgroundMobile } alt="supermine" />
        </ImageMobileBackground>
        <Welcome />
        <ImagePolar>
          <img src={ images.polarMain } alt="supermine"/>
        </ImagePolar>
        <Stats />
        <Services />
        <Roadmap />
        <Footer />
        <SocialMenu right = {this.state.offset} />
      </Page>
    )
  }
}

const mapStateToProps = ({ userInfo }: IStoreState) => ({
  token: userInfo.token
})

export default withRouter(connect(mapStateToProps)(Home))
