import { motion } from 'framer-motion'
import React, { CSSProperties, useCallback } from 'react'
import { images } from '../../images'
import { IFarmer } from '../../models'
import {StyledCard} from './styles'

interface IProps {
  farmer: IFarmer
  isTitle?: boolean
  styles?: CSSProperties
  className?: string
  onClick?: () => void
  copied?: boolean
  onClickEdit?: () => void
  onClickCopy?: () => void
  onClickDelete?: () => void

}

export const FarmerCard: React.FC<IProps> = ({
  farmer,
  isTitle,
  styles,
  className,
  onClick,
  copied,
  onClickEdit,
  onClickCopy,
  onClickDelete
}) => {
  const classNames = `${ className || '' }`

  const editHandler = useCallback(
    () => {
      if (onClickEdit) onClickEdit()
    },
    [onClickEdit]
  )

  return (
    <>
      <StyledCard
        isTitle={isTitle}
        className={ classNames }
        style={ styles }
      >
        <motion.div
          style={{ display: 'flex' }}
          className={'name'}
          onClick={onClick}
          whileHover={{ opacity: 0.75 }}
        >
          <span>{farmer.name}</span>
        </motion.div>
        <div style={{display: 'flex'}} className={'token'}>
          <span>{farmer.token}</span>
          {onClickEdit && (
            <img
              src={images.iconEdit}
              onClick={editHandler}
              className={'copy'}
            />
          )}
          {onClickCopy && (
            <img
              src={images.iconsCopy}
              onClick={onClickCopy}
              className={copied ? 'copied' : 'copy'}
            />
          )}
          {onClickDelete && (
            <img
              src={images.iconDelete}
              onClick={onClickDelete}
              className={'copy'}
            />
          )}
        </div>
        <div style={{display: 'flex'}} className={'plotCount'} >
          <span>{farmer.plotCount}</span>
        </div>
        <div style={{display: 'flex'}} className={'lastUpdate'} >
          <span>{farmer.lastUpdate}</span>
        </div>
      </StyledCard>
    </>
  )
}

