import styled from 'styled-components'

interface Props {
  hover?: boolean
}

export default styled.div<Props>`
  position: relative;
  display: inline-block;
  padding: 0;

  > button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0px;
  }

  .options {
    display: none;
    position: absolute;
    margin: 0 auto;
    margin-top: -5px;
    right: 0;
    left: 0;
    top: 34px;
    z-index: 2000;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
    /* min-width: 120px; */
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    background-color: ${({ theme }) => theme.colors.green}E6;
    padding: 0 0.5rem;
    font-family: ${({ theme }) => theme.fontMyriad};
  }

  &:hover .options {
    display: ${({ hover }) => hover && 'block'};
  }
`
