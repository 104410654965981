import styled from 'styled-components'

interface Props {
  isTitle?: boolean
}

export const  StyledCard = styled.div<Props>`
  background-color: ${ ({ theme, isTitle }) => isTitle ? theme.colors.transparent : theme.colors.mirage };
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 32px;


  margin-top: ${ ({ isTitle }) => isTitle ? '30px' : '10px' };

  @media screen and (min-width:40rem){

    margin-top: ${ ({ isTitle }) => isTitle ? '50px' : '10px' };
  }

  div {
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  span {
    color: ${ ({ theme }) => theme.colors.white };
    font-size: 12px;
    font-weight: 700;

    @media screen and (min-width:40rem){
      font-size: 16px;
    }
  }

  .name {
    cursor: pointer;

  }


  .token {
    display: flex;
    flex: 1;
    flex-grow: 3;
    min-width: 8px;
    overflow-x: hidden;
    margin-left: 5px;
  @media screen and (min-width:40rem){

  }

    span {
 
      max-width: 25vw;
      overflow: hidden;
      white-space: nowrap;
      /* text-overflow: ellipsis; */
    }

    img {
      margin-left: 8px;
      cursor: pointer;

      transition: all 0.1s ease-in;

      &.copied {
        background-color: ${ ({ theme }) => theme.colors.orient };
        cursor: not-allowed;
      }

      &.copy  {
        &:hover {
          background-color: ${ ({ theme }) => theme.colors.azure }50;
        }
      }
    }
  }

`
export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .goBackIconEdit {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 7px;
    top: 6px;
    cursor: pointer;
  }

  .txt {
    margin: 26px 28px 22px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #28F19C;
  }

  .renameSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 38px 24px;

    .tNameInput {
      padding: 0 10px;
      font-size: 17px;
      font-weight: bold;
      color: white;
      width: 335px;
      height: 34px;
      background: #131C29;
      box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
    }

    .confirmBtn {
      width: 141px;
      height: 40px;
      background: #029DF5;
      border-radius: 7px;
      border: none;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
`

