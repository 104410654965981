import { BaseHttpService } from './base'

class AuthenticationService extends BaseHttpService {
  public login = async (username: string, password: string ): Promise<any> => {
    return this.post('login', {username: username, password: password})
  }

  public logout = async (token: string ): Promise<any> => {
    /* eslint-disable-next-line  quote-props */
    return this.post('logout', { 'login_token' : token })
  }

  public register = async (info: any): Promise<any> => {
    return this.post('register', info)
  }

  public updateInfo = async (user: any): Promise<any> => {
    return this.put(`users/me/${user.id}`, user)
  }

}

export default new AuthenticationService()
