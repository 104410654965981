import React, { CSSProperties } from 'react'
import { ImageName, images } from '../../images'
import { Spinner } from '../Spinner'
import StyledButton from './styles'

interface IProps {
  title?: string
  filled?: boolean
  loading?: boolean
  isDisabled?: boolean
  upper?: boolean
  icon?: ImageName
  className?: string
  styles?: CSSProperties
  onPress?: () => any
}

export const Button: React.FC<IProps> = ({
  title,
  icon,
  filled,
  styles,
  isDisabled,
  loading,
  onPress,
  upper,
  className
}) => {
  const classNames = `${ className || '' } ${ filled ? 'filled' : '' }`
  const disabled = isDisabled || !onPress
  const buttonIcon = icon ? <img src={ images[icon] } className="icon" alt={ title } /> : null

  return (
    <StyledButton
      className={ classNames }
      style={ styles }
      onClick={ onPress }
      disabled={ disabled }
    >
      {
        loading ?
        <Spinner />
        :
        <>
          { buttonIcon }
          { upper ? title?.toUpperCase() : title }
        </>
      }
    </StyledButton>
  )
}
