import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  margin: 0 50px;
  min-width: 400px;
  max-width: 450px;
  position: relative;

  @media (max-width: 768px) {
    min-width: 200px;
    max-width: 280px;
    width: 80%;
    margin-top: 2rem;
    border-radius: 24px;
    margin: 0;
    padding: 12px 6px;
  }

  .title {
    position: absolute;
    left: -2px;
    top: -14px;
    width: 100%;
    z-index: 3;

    @media (max-width: 768px) {
      font-size: 24px;
      left: -2px;
      top: -5px;
    }

    h4 {
      margin: 0;
      font-size: 64pt;
      font-weight: 700;
      letter-spacing: 1pt;
      line-height: 80pt;
      color: ${ ({ theme }) => theme.colors.white };

      @media (max-width: 768px) {
        font-size: 54px;
        line-height: 60px;
      }
    }
  }

  .details {
    width: 100%;
    height: 480px;
    padding-top: 100px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: ${ ({ theme }) => theme.colors.greenText }80;
    border-radius: 18px;

    @media (max-width: 768px) {
      width: auto;
      height: 320px;
      padding-top: 50px;
      padding-left: 8px;
      padding-right: 8px;
    }

    h5 {
      margin: 0;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 1px;
      text-align: left;
      line-height: 40px;
      color: ${ ({ theme }) => theme.colors.black };

      @media (max-width: 768px) {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 20px;
      }
    }

    p, label {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1px;
      text-align: left;
      line-height: 30px;
      color: ${ ({ theme }) => theme.colors.white };

      @media (max-width: 768px) {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 18px;
      }
    }

    label {
      white-space: pre-wrap;
    }

    .bold {
      margin-top: 1rem;
      font-weight: 700;
    }
  }

`
