// import { Language } from '../../services/literals-service'
import {
  SettingsTypeKeys,
  IChangeLanguageAction,
  IGetSettingsAction,
  ISetIsGettingSettingsAction,
  IGetSettingsSuccessfulAction,
  ISetUpdateSettingsAction,
  ISetIsUpdatingSettingsAction,
  ISetUpdateSettingsSuccessfulAction,
  ISetSettingsUsernameAction,
  ISetSettingsRewardAddressAction,
  ISetSettingsThresholdAction,
  ISetUpdateSettingsFailedAction
} from '../types'

export function setChangeLanguageAction(payload: string): IChangeLanguageAction {
  return { type: SettingsTypeKeys.CHANGE_LANGUAGE, payload }
}

export function getSettingsAction(): IGetSettingsAction {
  return { type: SettingsTypeKeys.GET_SETTINGS }
}

export function setIsGettingSettingsAction(): ISetIsGettingSettingsAction {
  return { type: SettingsTypeKeys.GETTING_SETTINGS }
}

export function getSettingsSuccessfulAction(payload: any): IGetSettingsSuccessfulAction {
  return { type: SettingsTypeKeys.GET_SETTINGS_SUCCESSFUL, payload }
}

export function updateSettingsAction(): ISetUpdateSettingsAction {
  return { type: SettingsTypeKeys.UPDATE_SETTINGS }
}

export function setIsUpdatingSettingsAction(): ISetIsUpdatingSettingsAction {
  return { type: SettingsTypeKeys.UPDATING_SETTINGS }
}

export function updateSettingsSuccessfulAction(): ISetUpdateSettingsSuccessfulAction {
  return { type: SettingsTypeKeys.UPDATE_SETTINGS_SUCCESSFUL }
}

export function updateSettingsFailedAction(): ISetUpdateSettingsFailedAction {
  return { type: SettingsTypeKeys.UPDATE_SETTINGS_FAILED }
}

export function setSettingsUsernameAction(payload: string): ISetSettingsUsernameAction {
  return { type: SettingsTypeKeys.SET_SETTINGS_USERNAME, payload }
}

export function setSettingsRewardAddressAction(payload: string): ISetSettingsRewardAddressAction {
  return { type: SettingsTypeKeys.SET_SETTINGS_REWARD_ADDRESS, payload }
}

export function setSettingsThresholdAction(payload: number): ISetSettingsThresholdAction {
  return { type: SettingsTypeKeys.SET_SETTINGS_THERSHOLD, payload }
}
