import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { Page } from '../../components'
import { LiteralsService, StorageService } from '../../services'
import { checkSessionAction, setChangeLanguageAction } from '../../store/actions'

interface Props extends DispatchProp<AnyAction>, RouteComponentProps {

}

class Splash extends PureComponent<Props> {
  public async componentDidMount() {
    const { dispatch } = this.props
    const language = await StorageService.getLanguage()

    if (language) {
      LiteralsService.setLanguage(language)
      dispatch(setChangeLanguageAction(language))
    }

    dispatch(checkSessionAction())

    setTimeout(() => {
      // history.push(urls.home)
    }, 5000)
  }

  public render(): ReactNode {
    return (
      <Page style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {/* <Spinner /> */}
      </Page>
    )
  }
}

// export default connect()(Splash)
export default withRouter(connect()(Splash))
