import { IUserInfoState } from '../states'
import { UserInfoActionTypes, UserInfoTypeKeys } from '../types'

export const initialUserInfoState: IUserInfoState = {
  id: 0,
  name: '',
  username: '',
  email: '',
  token: '',
  isLoadingUserInfo: false,
  offsetYRoadmap: 0
}

const userInfo = (
  state: IUserInfoState = initialUserInfoState,
  action: UserInfoActionTypes
): IUserInfoState => {
  switch (action.type) {
    case UserInfoTypeKeys.SET_USER_INFO:
      return { ...state, ...action.payload }
    case UserInfoTypeKeys.SET_USER_INFO_USERNAME:
      return { ...state, username: action.payload }
    case UserInfoTypeKeys.SET_USER_INFO_EMAIL:
      return { ...state, email: action.payload }
    case UserInfoTypeKeys.SET_USER_INFO_TOKEN:
      return { ...state, token: action.payload }
    case UserInfoTypeKeys.SET_PAGE_OFFSET_Y_ROADMAP:
      return { ...state, offsetYRoadmap: action.payload }
    case UserInfoTypeKeys.IS_LOADING_USER_INFO:
      return {
        ...state,
        isLoadingUserInfo: action.payload
      }
    default:
      return state
  }
}

export default userInfo
