import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Page } from '../../components'
import { IStoreState } from '../../store/states'
import StyledArticle from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  username: string
  password: string
  isSigningIn: boolean
  error: boolean
}

class SignIn extends PureComponent<IProps> {
  public render(): ReactNode {

    return (
      <Page>
        <StyledArticle>
        </StyledArticle>
      </Page>
    )
  }

}

const mapStateToProps = ({ signIn }: IStoreState) => ({
  username: signIn.username,
  password: signIn.password,
  isSigningIn: signIn.isSigningIn,
  error: signIn.error
})

export default withRouter(connect(mapStateToProps)(SignIn))
