import {
  ICheckSessionAction,
  IGetUserSettingsAction,
  ISaveUserInfoAction,
  ISetIsLoadingUserInfoAction,
  ISetIsUserExperienced,
  ISetPageOffsetRoadmap,
  ISetUserInfoAction,
  ISetUserInfoEmailAction,
  ISetUserInfoNameAction,
  ISetUserInfoTokenAction,
  ISetUserInfoUserNameAction,
  UserInfoTypeKeys
} from '../types'

export function checkSessionAction(): ICheckSessionAction {
  return { type: UserInfoTypeKeys.CHECK_USER }
}

export function getUserSettingsAction(): IGetUserSettingsAction {
  return { type: UserInfoTypeKeys.GET_USER_SETTINGS }
}

export function setUserInfoAction(userInfo: any): ISetUserInfoAction {
  return { type: UserInfoTypeKeys.SET_USER_INFO, payload: userInfo }
}

export function setUserInfoEmailAction(email: string): ISetUserInfoEmailAction {
  return { type: UserInfoTypeKeys.SET_USER_INFO_EMAIL, payload: email }
}

export function setUserInfoNameAction(name: string): ISetUserInfoNameAction {
  return { type: UserInfoTypeKeys.SET_USER_INFO_NAME, payload: name }
}

export function setUserInfoUsernameAction(username: string): ISetUserInfoUserNameAction {
  return { type: UserInfoTypeKeys.SET_USER_INFO_USERNAME, payload: username }
}

export function setUserInfoTokenAction(token: string): ISetUserInfoTokenAction {
  return { type: UserInfoTypeKeys.SET_USER_INFO_TOKEN, payload: token }
}

export function saveUserInfoAction(): ISaveUserInfoAction {
  return { type: UserInfoTypeKeys.SAVE_USER_INFO }
}

export function setIsLoadingUserInfoAction(loading: boolean): ISetIsLoadingUserInfoAction {
  return { type: UserInfoTypeKeys.IS_LOADING_USER_INFO, payload: loading }
}

export function setIsUserExperienced(payload: boolean): ISetIsUserExperienced {
  return { type: UserInfoTypeKeys.SET_IS_USER_EXPERIENCED, payload }
}

export function setPageOffsetRoadmap(payload: number): ISetPageOffsetRoadmap {
  return { type: UserInfoTypeKeys.SET_PAGE_OFFSET_Y_ROADMAP, payload }
}
