export enum SignUpTypeKeys {
  CLEAR_SIGN_UP_FIELDS = 'CLEAR_SIGN_UP_FIELDS',
  OPEN_SIGN_UP_POPUP = 'OPEN_SIGN_UP_POPUP',
  SET_SIGN_UP_EMAIL = 'SET_SIGN_UP_EMAIL',
  SET_SIGN_UP_PASSWORD = 'SET_SIGN_UP_PASSWORD',
  SET_SIGN_UP_NAME = 'SET_SIGN_UP_NAME',
  SET_SIGN_UP_USERNAME = 'SET_SIGN_UP_USERNAME',
  SET_SIGN_UP_TERMS = 'SET_SIGN_UP_TERMS',
  SIGN_UP = 'SIGN_UP',
  SIGNING_UP = 'SIGNING_UP',
  SIGN_UP_SUCCESSFUL = 'SIGN_UP_SUCCESSFUL',
  SIGN_UP_FAILED = 'SIGN_UP_FAILED'
}

export interface IClearSignUpFieldsAction {
  type: typeof SignUpTypeKeys.CLEAR_SIGN_UP_FIELDS
}

export interface IOpenSignUpPopupAction {
  type: typeof SignUpTypeKeys.OPEN_SIGN_UP_POPUP
  payload: boolean
}

export interface ISetSignUpEmailAction {
  type: typeof SignUpTypeKeys.SET_SIGN_UP_EMAIL
  payload: string
}

export interface ISetSignUpPasswordAction {
  type: typeof SignUpTypeKeys.SET_SIGN_UP_PASSWORD
  payload: string
}

export interface ISetSignUpNameAction {
  type: typeof SignUpTypeKeys.SET_SIGN_UP_NAME
  payload: string
}

export interface ISetSignUpUsernameAction {
  type: typeof SignUpTypeKeys.SET_SIGN_UP_USERNAME
  payload: string
}

export interface ISetSignUpTermsAndConditionsAction {
  type: typeof SignUpTypeKeys.SET_SIGN_UP_TERMS
}

export interface ISignUpAction {
  type: typeof SignUpTypeKeys.SIGN_UP
}

export interface ISetIsSigningUpAction {
  type: typeof SignUpTypeKeys.SIGNING_UP
}

export interface ISignUpSuccessfulAction {
  type: typeof SignUpTypeKeys.SIGN_UP_SUCCESSFUL
}

export interface ISignUpFailedAction {
  type: typeof SignUpTypeKeys.SIGN_UP_FAILED
  payload: any
}

export type SignUpActionTypes =
  | IClearSignUpFieldsAction
  | IOpenSignUpPopupAction
  | ISetSignUpEmailAction
  | ISetSignUpPasswordAction
  | ISetSignUpNameAction
  | ISetSignUpUsernameAction
  | ISetSignUpTermsAndConditionsAction
  | ISignUpAction
  | ISetIsSigningUpAction
  | ISignUpSuccessfulAction
  | ISignUpFailedAction
