import { motion, Variants } from 'framer-motion'
import React from 'react'
import { ImageName, images } from '../../../../images'
import StyledSection from './styles'

interface IProps {
  title: string
  isNumber: boolean
  number?: number
  subTitle?: string
  isLeft: boolean
  topBorder?: boolean
  bottomBorder?: boolean
  titleFontSize? :number
  subtitleFontSize? :number
  iconName: ImageName
  variants?: Variants
}

export const StatsItem: React.FC<IProps> = ({
  title,
  isNumber,
  number,
  subTitle,
  isLeft,
  topBorder,
  bottomBorder,
  iconName,
  variants,
  titleFontSize,
  subtitleFontSize
}) => {
  return (
    <StyledSection isLeft={isLeft} topBorder={topBorder} bottomBorder={bottomBorder}>
      <motion.div variants={variants}>
        <motion.h4 variants={variants} style={{fontSize: titleFontSize}}>{title}</motion.h4>
        <motion.p variants={variants} style={{fontSize: subtitleFontSize}}>{isNumber ? number :  subTitle}</motion.p>
        <motion.img src={images[iconName]} alt={title}  variants={variants} />
      </motion.div>
    </StyledSection>
  )
}
