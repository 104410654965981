import React, {useEffect} from 'react'

export const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth)
  // Add a second state variable "height" and default it to the current window height
  const [height, setHeight] = React.useState(window.innerHeight)

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth)
      // Set the height in state as well as the width
      setHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  // Return both the height and width
  return { width, height }
}
