import styled from 'styled-components'

interface Props {
  showMenu: boolean
}

export default styled.section<Props>`
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;
  
  background-color: ${({ theme }) => theme.colors.transparent};
  /* top: 2rem; */

  header {
    min-height: 140px;
    max-width: 100rem;
    padding: 0 2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    transition: all 0.2s ease;

    @media (max-width: 768px) {
      min-height: 100px;
      flex-direction: column;
      background-color: ${({ theme, showMenu }) => (showMenu ? theme.colors.white : theme.colors.transparent)};
    }

    @media (min-width: 768px) {
      background-color: ${({ theme }) => theme.colors.transparent};
    }

    .logo {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 768px) {
        min-height: 100px;
        width: 100%;
      }

      img {
        height: 100px;
        width: auto;
        position: relative;
        top: 2px;

        @media (max-width: 768px) {
          height: 80px;
        }
      }

      span {
        margin-left: 1.5rem;
        font-size: 42px;
        font-weight: 600;

        @media (max-width: 768px) {
          font-size: 32px;
        }

        @media (max-width: 1024px) and (min-width: 768px) {
          display: none;
        }
      }

      .menu-button {
        display: none;
        max-width: 44px;
        width: auto;
        /* height: 32px; */
        border: none;
        overflow: hidden;
        background-color: transparent;
        cursor: pointer;
        padding: 0;
        img {
          width: auto;
          height: 32px;
        }
        @media (max-width: 768px) {
          display: block;
        }
      }
    }

    .navigation {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .menuItem {
        padding: 4px 20px;
        margin: 0;
        min-width: 160px;
        font-family: ${({ theme }) => theme.fontMyriad};
        font-size: 20px;
        text-transform: uppercase;
        font-weight: normal;
        color: ${({ theme }) => theme.colors.black};
        text-decoration: none;
        border: 3px solid transparent;
        box-sizing: border-box;
        height: 100%;
        display: block;
        text-align: center;
        transition: all 0.2s ease;
        background-color: ${({ theme }) => theme.colors.transparent};
        cursor: pointer;

        &.active {
          border-bottom-color: ${({ theme }) => theme.colors.green};
          background-color: ${({ theme }) => theme.colors.green};
        }

        &:hover {
          background-color: ${({ theme }) => theme.colors.green}80;
        }
      }

      .options {
        top: 42px;

        span {
          font-size: 16px;
          font-weight: 400;
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .navigation-mobile {
      display: none;
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        justify-content: flex-start;
        padding: ${({ showMenu }) => (showMenu ? '24px 0' : '0')};
      }

      .mobile-menu-item,
      .mobile-menu-sub-item {
        background-color: ${({ theme }) => theme.colors.transparent};
        color: ${({ theme }) => theme.colors.black};
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-decoration: none;
        border: none;
        height: 30px;
        padding: 12px 20px;
        width: 100%;
        flex: 1;
        text-align: left;
        cursor: pointer;
        transition: all 0.1s ease;

        p {
          padding: 0;
          margin: 0;
        }
      }
      @media screen and (min-width: 40rem) {
        .mobile-menu-item:hover {
          opacity: 0.7;
        }
      }

      .mobile-menu-sub-item {
        margin-left: 2rem;
        font-size: 16px;
        border-left-color: ${({ theme }) => theme.colors.black};
        border-left-width: 2px;
        border-left-style: solid;

        &:active {
          opacity: 0.5;
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
`
