import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { LiteralsService } from '../../services'
import { IStoreState } from '../../store/states'
import {
  openSignInPopupAction,
  openSignUpPopupAction,
  setSignUpEmailAction,
  setSignUpPasswordAction,
  setSignUpUsernameAction
} from '../../store/actions'
import { Modal, ValidatedInput, Button } from '../../components'
import { isEmailValid, isPasswordValid } from '../../helpers'
import StyledArticle from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  isOpen: boolean
  username: string
  email: string
  password: string
  error: boolean
  loading: boolean
}

interface IState {
  repeatPassword: string
}


class Register extends PureComponent<IProps, IState> {

  private get isUsernameValid(): boolean {
    return isEmailValid(this.props.username)
  }

  private get isPasswordValid(): boolean {
    return isPasswordValid(this.props.password)
  }

  private get modalContent(): ReactNode {
    const { email, username, password, error } = this.props
    const { repeatPassword } = this.state

    return (
      <StyledArticle>
        <form onSubmit={ e => e.preventDefault() }>
          <header>
            <h3>
            { LiteralsService.get('register', true).toUpperCase() }
            </h3>
          </header>
          <section className="content" style={{ marginTop: '20px' }}>
            <ValidatedInput
              name="username"
              value={ username }
              label={ LiteralsService.get('username', true).toUpperCase() }
              onChange={ this.onUsernameChange }
              error={ !this.isUsernameValid || error }
            />
            <ValidatedInput
              name="password"
              value={ password }
              label={ LiteralsService.get('password', true).toUpperCase() }
              onChange={ this.onPasswordChange }
              error={ !isPasswordValid(password) }
              isPassword
            />
            <ValidatedInput
              name="repeat-password"
              label={ LiteralsService.get('confirmPassword', true) }
              value={ repeatPassword }
              onChange={ this.onRepeatPasswordChange }
              error={ !isPasswordValid(repeatPassword) || password !== repeatPassword }
              isPassword
            />
            <ValidatedInput
              name="email"
              value={ email }
              label={ LiteralsService.get('email', true).toUpperCase() }
              onChange={ this.onEmailChange }
              error={ !isEmailValid(email) }
              />
          </section>
          <section className="actions">
            <Button
              onPress={ this.onRegister }
              title={ LiteralsService.get('register', true).toUpperCase() }
              styles={{ width: '250px', height: '50px', marginTop: '35px' }}
              filled
            />
            <div className="link">
              <span>
                { LiteralsService.get('alreadyHaveAccount', true).toUpperCase() }
              </span>
            </div>
            <Button
              onPress={ this.onLogin }
              title={ LiteralsService.get('login', true).toUpperCase() }
              styles={{ width: '200px', marginTop: '10px' }}
              filled
            />
          </section>
        </form>
      </StyledArticle>
    )
  }

  constructor(props: IProps) {
    super(props)
    this.state = { repeatPassword: '' }
  }

  public render(): ReactNode {
    const { isOpen } = this.props
    return (
      <Modal className="small" isHidden={ !isOpen } onOverlayClick={ this.handleClose }>
        { this.modalContent }
      </Modal>
    )
  }

  private handleClose = () => this.props.dispatch(openSignUpPopupAction(false))

  private onUsernameChange = (username: string) => {
    const { dispatch } = this.props
    dispatch(setSignUpUsernameAction(username))
  }

  private onEmailChange = (email: string) => {
    const { dispatch } = this.props
    dispatch(setSignUpEmailAction(email))
  }

  private onPasswordChange = (password: string) => {
    const { dispatch } = this.props
    dispatch(setSignUpPasswordAction(password))
  }

  private onRepeatPasswordChange = (repeatPassword: string) => {
    this.setState({ repeatPassword })
  }

  private onRegister = () => {
    this.handleClose()
  }

  private onLogin = () => {
    const { dispatch } = this.props
    this.handleClose()
    dispatch(openSignInPopupAction(true))
  }
}

const mapStateToProps = ({ signUp }: IStoreState) => ({
  isOpen: signUp.openSignUpPopup,
  email: signUp.email,
  username: signUp.username,
  password: signUp.password,
  error: signUp.error,
  loading: signUp.isSigningUp
})

export default withRouter(connect(mapStateToProps)(Register))
