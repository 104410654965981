import React, { useEffect } from 'react'
import { Switch } from 'react-router-dom'
import ReactModal from 'react-modal'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { Route } from './components'
import { routes } from './routing'
import {
  Menu,
  Footer,
  SideMenu,
  ProtectedRoute,
  NavigationMenu,
  Login,
  Register,
  NotificationContainer
}
from './containers'
import { IStoreState } from './store/states'
import { checkSessionAction } from './store/actions'

ReactModal.setAppElement('#supermine-io')

interface Props extends DispatchProp<AnyAction> {
  username: string
}

const App: React.FC<Props> = (props: Props) => {
  const { dispatch } = props
  useEffect(() => {
    dispatch(checkSessionAction())
  }, [dispatch])

  const getContent = (route: any) => {
    return (
      <>
        {
          route.isMenuVisible === false ? null :
          route.sideMenu ? <NavigationMenu /> : <Menu />
        }
        {
          route.sideMenu ?
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <SideMenu />
            <route.content />
          </div>
          :
          <route.content />
        }
        { route.isFooterVisible ? <Footer /> : null }
      </>
    )
  }

  const pages = routes.map((route: any, i: number) => (
    route.isAuthenticated ?
      <ProtectedRoute key={ i.toString() } path={ route.url }>
        {getContent(route)}
      </ProtectedRoute>
      :
      <Route key={ i.toString() } path={ route.url }>
        {getContent(route)}
      </Route>
  ))

  return (
    <>
      <Switch>
        { pages }
      </Switch>
      <Login />
      <Register />
      <NotificationContainer />
    </>
  )
}

const mapStateToProps = ({ userInfo }: IStoreState) => ({
  username: userInfo.username
})

export default connect(mapStateToProps)(App)
