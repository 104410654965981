import styled from 'styled-components'

export default styled.article`
  display: flex;
  position: fixed;
  background: #083E5AAA;
  flex-direction: column;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 300;

  .frame {
    display: flex;
    flex-direction: column;
    /* background: white; */
    background: rgb(91,154,86);
    background: linear-gradient(0deg, rgba(91,154,86,1) 0%, rgba(130,221,127,1) 100%);
    width: auto;
    border-radius: 4px;
    padding: 16px 8px;
    box-shadow: 0 4px 10px 0 rgba(66, 74, 97, 0.15);
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.small {
    .frame {
      width: 450px;
      padding: 60px 30px;
      box-sizing: border-box;
      border-radius: 20px;

      .spinner-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 40px;
      }

      header{
        h3 {
          font-size: 48px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 60px;
          text-align: center;
          margin: 0 0 80px;
        }
      }

      .content {
        p {
          margin: 0;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
        }
      }

      footer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        padding: 0;
        min-height: auto;
      }
    }
  }
`
