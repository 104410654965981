import React, { useEffect, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { LiteralsService } from '../../../services'
import { images } from '../../../images'
import { useViewport } from '../../../hooks'
import { ServiceItem } from './ServiceItem'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteProps {

}

const Services: React.FC<IProps> = () => {
  const animation = useAnimation()
  const [refInView, inView] = useInView({ threshold: 0.2 })
  const [showValue, setShowValue] = useState(false)
  const { width } = useViewport()
  const breakpoint = 768

  const variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.3, delayChilden: 0.2, staggerChildren: 0.2 }
    },
    hidden: {
      y: 0,
      opacity: 0
    }
  }

  useEffect(() => {
    if (width > breakpoint) {
      setShowValue(true)
    }
    if (inView) {
      animation.start('visible')
    } else {
      // animation.start('hidden')
    }
  }, [inView, animation, showValue, width])

  return(
    <StyledSection>
      <motion.div ref={refInView} animate={animation} initial={'hidden'} variants={variants}>
        <motion.h3>{LiteralsService.get('ourPlatform')}</motion.h3>
        <button onClick={() => setShowValue(value => !value)} className="showButton">
          {
            showValue ?
            <img src={images.iconDashboardArrowUp} /> :
            <img src={images.iconDashboardArrowDown} />
          }
        </button>
        <motion.div className="items" variants={variants}>
          {
            showValue &&
            <>
              <ServiceItem
                title={LiteralsService.get('iceBreakerTitle')}
                normalDescription={LiteralsService.get('iceBreakerDescription1')}
                normalDescription2={LiteralsService.get('iceBreakerDescription2')}
                boldDescription={LiteralsService.get('iceBreakerDescription3')}
                variants={variants}
              />
              <ServiceItem
                title={LiteralsService.get('userDashboardTitle')}
                normalDescription={LiteralsService.get('userDashboardDescription1')}
                normalDescription2={LiteralsService.get('userDashboardDescription2')}
                boldDescription={LiteralsService.get('userDashboardDescription3')}
                variants={variants}
              />
            </>
          }
        </motion.div>
      </motion.div>
    </StyledSection>
  )
}


export default connect()(Services)
