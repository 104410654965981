import styled from 'styled-components'

interface Props {
  right: number
}

export default styled.section<Props>`
  background-color: ${ ({ theme }) => theme.colors.transparent };
  position: fixed;
  width: 60px;
  z-index: 200;
  box-sizing: border-box;
  top: 25vh;
  /* right: ${ ({ right }) => right / 2 + 'px' }; */
  right: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    display: none;
  }

  button {
    width: 40px;
    height: 40px;
    margin-top: 1rem;
    background-color: ${ ({ theme }) => theme.colors.transparent };
    border: 0;
    border-radius: 6px;
    transition: all 0.1s ease;
    padding: 0;
    cursor: pointer;

    img{
      width: 100%;
      height: auto;
    }
  }
`
