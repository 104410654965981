import styled from 'styled-components'

export default styled.section`
  margin-bottom: 36px;

  h2 {
    margin: 0;
    font-size: 22px;
    font-weight: bold;
    color: ${ ({ theme }) => theme.colors.azure };

    @media (max-width: 768px) {
      font-size: 20px;
      letter-spacing: 0px;
    }
  }
`
