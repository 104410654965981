import React, { PureComponent, ReactNode } from 'react'
import { Provider } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Store } from 'redux'
import { persistStore } from 'redux-persist'
import { Persistor } from 'redux-persist/lib/types'
import { PersistGate } from 'redux-persist/es/integration/react'
import configureStore from '../../store'

type Props = RouteComponentProps

class ProviderWithRouter extends PureComponent<Props> {
  private store: Store
  private persistor: Persistor

  constructor(props: Props) {
    super(props)
    this.store = configureStore({}, { routerHistory: props.history })
    this.persistor = persistStore(this.store)
  }

  public render(): ReactNode {
    return (
      <Provider store={ this.store }>
        <PersistGate persistor={this.persistor} loading={null}>
          { this.props.children }
        </PersistGate>
      </Provider>
    )
  }
}

export default withRouter(ProviderWithRouter)
