import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { LiteralsService } from '../../services'
import { IStoreState } from '../../store/states'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  username: string
}

class Footer extends PureComponent<IProps> {

  public render(): ReactNode {
    return (
      <StyledSection>
        <div className={'aboutUs'}>
          <div className="about">
            <h4>{LiteralsService.get('aboutUs')}</h4>
            <p>{LiteralsService.get('aboutUsDescription')}</p>
          </div>
          <div className="contact">
            <h4>{LiteralsService.get('contactUs')}</h4>
            <p>{LiteralsService.get('contactUsQuestions')}</p>
            <a href="mailto:info@supermine.io" style={{textDecoration: 'none'}}>
              <p>{'info@supermine.io'}</p>
            </a>
          </div>
        </div>
        <div className={'copyright'}>
          <div className={'content'}>
            <div>
              <p>{LiteralsService.get('copyright') + ' ' + LiteralsService.get('supermine')}</p>
            </div>
            <div>
              <p>{'Website Design by Aleksandar Mitic'}</p>
            </div>
          </div>
        </div>
      </StyledSection>
    )
  }

}

const mapStateToProps = ({ userInfo }: IStoreState) => ({
  username: userInfo.username
})

export default withRouter(connect(mapStateToProps)(Footer))
