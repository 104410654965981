import { BaseHttpService } from './base'

class SettingService extends BaseHttpService {
  public getUserSettings = async (token: string): Promise<any> => {
    /* eslint-disable-next-line  quote-props */
    return this.post('get-user-settings', { 'login_token': token })
  }

  public updateSettings = async (
    token: string,
    username: string,
    rewardAddress: string,
    threshold: string
  ): Promise<any> => {
    /* eslint-disable  quote-props */
    return this.post('update-user-settings', {
      'token': token,
      'new_username': username,
      'new_reward_addr': rewardAddress,
      'new_threshold': threshold
    })
  }
}

export default new SettingService()
