import React, { useRef, useState, CSSProperties } from 'react'
import { IMenuOption } from '../../models'
import { Option } from './Option'
import StyledDiv from './styles'

interface IProps {
  style?: CSSProperties
  optionStyle?: CSSProperties
  isDisabled?: boolean
  isHidden?: boolean
  isCheck?: boolean
  autoDropdown?: boolean
  options: IMenuOption[]
  selectedOptions?: IMenuOption[]
  onChangeItems?: (items: IMenuOption) => void
  onClosed?: () => void
}

export const Dropdown: React.FC<IProps> = ({
  options,
  isDisabled,
  isHidden,
  isCheck,
  autoDropdown,
  children,
  style,
  optionStyle,
  selectedOptions,
  onChangeItems
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLUListElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const onMouseEnter = () => {
    if (window.innerWidth < 769) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  const onMouseLeave = () => {
    if (window.innerWidth < 769) {
      setIsOpen(false)
    } else {
      setIsOpen(false)
    }
  }

  const toggleOpen = () => {
    setIsOpen((current) => !current)
  }


  const isSelected = (s: IMenuOption): boolean => {
    if (!selectedOptions || selectedOptions.length === 0) return false
    if (selectedOptions?.filter((f) => f.label === s.label).length > 0) return true
    return false
  }

  const optionsStyle = (): CSSProperties => {
    let css: CSSProperties = { display: 'none' }
    if (autoDropdown) css = {}
    else if (isOpen) css = { display: 'block' }
    return {
      ...optionStyle,
      ...css
    }
  }

  const menuOptions = (
    <ul className="options" ref={listRef} style={optionsStyle()}>
      {options.map((o, i) => (
        <Option
          key={i}
          option={o}
          onClick={
            !isCheck
              ? o.isDisableToggle
                ? () => {
                    console.log('disable toggle')
                  }
                : toggleOpen
              : () => onChangeItems && onChangeItems(o)
          }
          isCheck={isCheck}
          selected={isSelected(o)}
        />
      ))}
    </ul>
  )

  if (isHidden) return null
  return (
    <StyledDiv ref={ref} style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <button onClick={toggleOpen} disabled={isDisabled}>
        {children}
      </button>
      {isOpen && menuOptions}
    </StyledDiv>
  )
}
