export enum UserInfoTypeKeys {
  CHECK_USER = 'CHECK_USER',
  GET_USER_SETTINGS = 'GET_USER_SETTINGS',
  SET_USER_INFO = 'SET_USER_INFO',
  SET_USER_INFO_EMAIL = 'SET_USER_INFO_EMAIL',
  SET_USER_INFO_NAME = 'SET_USER_INFO_NAME',
  SET_USER_INFO_USERNAME = 'SET_USER_INFO_USERNAME',
  SET_USER_INFO_TOKEN = 'SET_USER_INFO_TOKEN',
  SAVE_USER_INFO = 'SAVE_USER_INFO',
  CHANGE_FEEDBACK_FLAG = 'CHANGE_FEEDBACK_FLAG',
  CHANGE_CONFIRMATION_FLAG = 'CHANGE_CONFIRMATION_FLAG',
  IS_LOADING_USER_INFO = 'IS_LOADING_USER_INFO',
  SET_PAGE_OFFSET_Y_ROADMAP = 'SET_PAGE_OFFSET_Y_ROADMAP',
  SET_IS_USER_EXPERIENCED = 'SET_IS_USER_EXPERIENCED'
}

export interface ICheckSessionAction {
  type: UserInfoTypeKeys.CHECK_USER
}

export interface IGetUserSettingsAction {
  type: UserInfoTypeKeys.GET_USER_SETTINGS
}

export interface ISetUserInfoAction {
  type: UserInfoTypeKeys.SET_USER_INFO
  payload: any
}

export interface ISetUserInfoEmailAction {
  type: UserInfoTypeKeys.SET_USER_INFO_EMAIL
  payload: string
}

export interface ISetUserInfoNameAction {
  type: UserInfoTypeKeys.SET_USER_INFO_NAME
  payload: string
}

export interface ISetUserInfoUserNameAction {
  type: UserInfoTypeKeys.SET_USER_INFO_USERNAME
  payload: string
}

export interface ISetUserInfoTokenAction {
  type: UserInfoTypeKeys.SET_USER_INFO_TOKEN
  payload: string
}

export interface ISaveUserInfoAction {
  type: UserInfoTypeKeys.SAVE_USER_INFO
}

export interface ISetIsLoadingUserInfoAction {
  type: UserInfoTypeKeys.IS_LOADING_USER_INFO
  payload: boolean
}

export interface ISetIsUserExperienced {
  type: UserInfoTypeKeys.SET_IS_USER_EXPERIENCED
  payload: boolean
}

export interface ISetPageOffsetRoadmap {
  type: UserInfoTypeKeys.SET_PAGE_OFFSET_Y_ROADMAP
  payload: number
}

export type UserInfoActionTypes =
  | ICheckSessionAction
  | ISetUserInfoAction
  | ISetUserInfoEmailAction
  | ISetUserInfoNameAction
  | ISetUserInfoUserNameAction
  | ISetUserInfoTokenAction
  | ISaveUserInfoAction
  | ISetIsLoadingUserInfoAction
  | ISetIsUserExperienced
  | ISetPageOffsetRoadmap
