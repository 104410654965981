import { BaseHttpService } from './base'

class DashboardService extends BaseHttpService {
  public getDashboard = async (token: string): Promise<any> => {
    /* eslint-disable-next-line  quote-props */
    return this.post('update-dashboard', { 'login_token': token })
  }

  public createFarmerToken = async (token: string, name: string): Promise<any> => {
    /* eslint-disable-next-line  quote-props */
    return this.post('create-farmer-token', { 'login_token': token, 'token_name': name })
  }

  public renameFarmerToken = async (token: string, name: string, farmerToken: string): Promise<any> => {
    /* eslint-disable-next-line  quote-props */
    return this.post('change-token-name', { 'login_token': token, 'new_name': name,'farmer_token': farmerToken })
  }

  public deleteFarmerToken = async (token: string, farmerToken: string): Promise<any> => {
    /* eslint-disable-next-line  quote-props */
    return this.post('delete-farmer-token', { 'login_token': token, 'farmer_token': farmerToken })
  }

  public changeFarmerToken = async (token: string, login: string, newName: string): Promise<any> => {
    /* eslint-disable  quote-props */
    return this.post('change-token-name', {
      'login_token': login,
      'farmer_token': token,
      'new_name': newName
    })
  }

}

export default new DashboardService()
