export type ImageName = keyof typeof images

export const images = {
  logo: '/assets/images/logo.png',
  clouds: '/assets/images/clouds.png',
  cloudsWithWater: '/assets/images/clouds-with-water.png',
  iconDiscord: '/assets/images/icon-discord.png',
  iconReddit: '/assets/images/icon-reddit.png',
  iconTelegram: '/assets/images/icon-telegram.png',
  iconTwitter: '/assets/images/icon-twitter.png',
  iconYoutube: '/assets/images/icon-youtube.png',
  polarMain: '/assets/images/polar-main.png',
  polarBear: '/assets/images/polarbear.png',
  iconDatabase: '/assets/images/icon-database.png',
  iconDaynight: '/assets/images/icon-daynight.png',
  iconDoughnut: '/assets/images/icon-doughnut.png',
  iconHarddisk: '/assets/images/icon-harddisk.png',
  icon24Hours: '/assets/images/icon-hours.png',
  iconStorage: '/assets/images/icon-storage.png',
  iconSync: '/assets/images/icon-sync.png',
  iconUser: '/assets/images/icon-user.png',
  iceberg: '/assets/images/iceberg.png',
  iconInfo: '/assets/images/ic-info.png',
  signInGoogle: '/assets/images/signingoogle.png',
  iconDashboard: '/assets/images/icon-dashboard.png',
  iconDiscordGreen: '/assets/images/icon-discord-green.png',
  iconDownloadingFile: '/assets/images/icon-download-file.png',
  iconMenu: '/assets/images/icon-menu-green.png',
  iconFlag: '/assets/images/icon-flag-green.png',
  iconHome: '/assets/images/icon-home-green.png',
  iconHowToJoin: '/assets/images/icon-howtojoin.png',
  iconLogout: '/assets/images/icon-logout-green.png',
  iconRefresh: '/assets/images/icon-refresh-green.png',
  iconSettings: '/assets/images/icon-settings-green.png',
  iconUserGreen: '/assets/images/icon-user-green.png',
  comingSoonBear: '/assets/images/coming-soon-bear.png',
  iconCheck: '/assets/images/icon-checkmark.png',
  iconGoBack: '/assets/images/icon-goback.png',
  iconBug: '/assets/images/icon-bug.png',
  logoChia: '/assets/images/logo-chia.png',
  logoFlax: '/assets/images/logo-flax.png',
  flagChina: '/assets/flags/Flag-China.png',
  flagFrance: '/assets/flags/Flag-France.png',
  flagGermany: '/assets/flags/Flag-Germany.png',
  flagIndia: '/assets/flags/Flag-India.png',
  flagItaly: '/assets/flags/Flag-Italy.png',
  flagPhilippines: '/assets/flags/Flag-Philippines.png',
  flagRussia: '/assets/flags/Flag-Russia.png',
  flagSerbia: '/assets/flags/Flag-Serbia.png',
  flagSpain: '/assets/flags/Flag-Spain.png',
  flagThailand: '/assets/flags/Flag-Thailand.png',
  flagTurkey: '/assets/flags/Flag-Turkey.png',
  flagUkraine: '/assets/flags/Flag-Ukraine.png',
  flagUnitedStates: '/assets/flags/Flag-UnitedStates.png',
  flagVietnam: '/assets/flags/Flag-Vietnam.png',
  goBack: '/assets/images/go-back-arrow.png',
  iconMenuMobile: '/assets/images/icon-menu-mobile.png',
  iconEyeOpen: '/assets/images/icon-eye-open.png',
  iconEyeClosed: '/assets/images/icon-eye-closed.png',
  iconExitMobile: '/assets/images/icon-exit-mobile.png',
  iconDashboardArrowUp: '/assets/images/icon-dashboard-arrow-up.png',
  iconDashboardArrowDown: '/assets/images/icon-dashboard-arrow-down.png',
  iconCopy: '/assets/images/icon-copy.png',
  iconArrowDownMobile: '/assets/images/icon-arrow-down-menu-mobile.png',
  iconArrowDownGreen: '/assets/images/icon-arrow-down-green.png',
  backgroundMobile: '/assets/images/background-mobile.png',
  iconChiaUsd: '/assets/images/icon-chiausd.png',
  iconChronometer: '/assets/images/icon-chronometer.png',
  iconFarmer: '/assets/images/icon-farmer.png',
  ChiaImage : '/assets/images/chialogo.svg',
  FlaxImage : '/assets/images/flexlogo.svg',
  hddLogo : 'assets/images/hdd_logo.png',
  iconEdit: 'assets/images/edit.svg',
  iconDelete: 'assets/images/trash-delete.svg',
  iconsCopy: 'assets/images/copy.svg',
  chiaLogo: 'assets/images/logo-chia.png',
  activeEye: 'assets/images/activeEye.png',
  eye: 'assets/images/eye.png'
}
