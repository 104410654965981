import styled from 'styled-components'

export default styled.div`
  min-width: 100vw;
  height: 50px;
  background-color: ${ ({ theme }) => theme.colors.greenNotification };
  overflow: hidden;
  z-index: 1000;
  position: fixed;
  top: 0px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  animation-name: appear, hide;
  animation-duration: 0.2s, 0.2s;
  animation-timing-function: ease-in-out, ease-in-out;
  animation-iteration-count: 1, 1;
  text-align: center;

  &.error {
    background-color: ${ ({ theme }) => theme.colors.red };
  }

  > p {
    display: inline-block;
    font-family: ${ ({ theme }) => theme.fontFamily };
    font-weight: 600;
    color: ${ ({ theme }) => theme.colors.white };
    text-align: center;
    /* flex: 1; */
    align-items: center;
    justify-content: center;
    /* padding: 6px 20px; */
    font-size: 16px;
    text-align: center;
  }

  // TODO: Notification animation
  /* @keyframes appear {
    10% {
      max-height: 65px;
    }
  }
  @keyframes hide {
    100% {
      max-height: 0;
    }
  } */
`
