import { motion } from 'framer-motion'
import React, {PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { images } from '../../images'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  right: number
}

class SocialMenu extends PureComponent<IProps> {

  private icons: any[]

  constructor(props: IProps) {
    super(props)

    this.icons = [
      {
        icon: images.iconDiscord,
        link: 'https://discord.gg/27TnmnqChh'
      },
      {
        icon: images.iconYoutube,
        link: 'https://www.youtube.com/channel/UCRd3F81sp3RwSXp8QJ5ABCQ'
      },
      {
        icon: images.iconTwitter,
        link: 'https://twitter.com/PolarMine_io'
      },
      {
        icon: images.iconReddit,
        link: 'https://www.reddit.com/r/PolarMine_Chia_Pool/'
      },
      {
        icon: images.iconTelegram,
        link: 'https://t.me/joinchat/D3mqGGYxJY40YTk1'
      }
    ]
  }

  public render(): ReactNode {
    const { right } = this.props
    return (
      <StyledSection right={right}>
        {
          this.icons.map((i, index) => (
            <motion.button
              key={index.toString()}
              whileHover={{
                scale: 1.1,
                transition: { duration: 0.05 }
              }}
              whileTap={{ scale: 0.9 }}
              onClick={() => { window.open(i.link, '_blank') }}
            >
              <img src={i.icon}/>
            </motion.button>
          ))
        }
      </StyledSection>
    )
  }

}

export default withRouter(connect()(SocialMenu))
