import styled from 'styled-components'

export default styled.button`
  font-family: ${ ({ theme }) => theme.fontFamily };
  font-weight: bold;
  height: 44px;
  width: 100%;
  border-radius: 2px;
  border-width: 1.5px;
  border: none;
  /* border: solid 1.5px ${ ({ theme }) => theme.colors.primary }; */
  color: ${ ({ theme }) => theme.colors.black };
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);
  font-size: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* TODO Finish styles */
  &.filled {
    color: ${ ({ theme }) => theme.colors.black };
    background-color: ${ ({ theme }) => theme.colors.white };
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
    transition: all 0.1s ease-in-out ;
    border-color: transparent;

    &:hover {
      background-color: ${ ({ theme }) => theme.colors.white }B3;
    }

    &:active {
      background-color: ${ ({ theme }) => theme.colors.white }8C;
    }

    &:disabled {
      background-color: ${ ({ theme }) => theme.colors.grey }90;
      border-color: transparent;
    }

  }

  .icon {
    /* width: 16px; */
    max-width: 100%;
    max-height: 100%;
    height: auto;
    margin-right: 10px;
  }

  &:disabled {
    background-color: ${ ({ theme }) => theme.colors.white }90;
    cursor: not-allowed;
  }
`

