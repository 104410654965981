import styled from 'styled-components'

interface Props {
  isLeft: boolean
  topBorder?: boolean
  bottomBorder?: boolean
}

export default styled.section<Props>`
  background-color: ${ ({ theme }) => theme.colors.lightGreen };
  border-radius: 8px;
  border-top-left-radius: ${ ({ isLeft, topBorder }) => isLeft && topBorder ? '24px' : '8px' };
  border-bottom-left-radius: ${ ({ isLeft, bottomBorder }) => isLeft && bottomBorder ? '24px' : '8px' };
  border-top-right-radius: ${ ({ isLeft, topBorder }) => !isLeft && topBorder ? '24px' : '8px' };
  border-bottom-right-radius: ${ ({ isLeft, bottomBorder }) => !isLeft && bottomBorder ? '24px' : '8px' };
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 100px;
  padding: 0px 24px;
  padding-left: ${ ({ isLeft }) => !isLeft && '24px' };

  @media (max-width: 768px) {
    width: 240px;
    height: 70px;
    margin-top: 2rem;
    border-radius: 24px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  div {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;

    h4 {
      margin: 0;
      text-align: left;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 1pt;
      line-height: 40px;
      text-align: ${ ({ isLeft }) => !isLeft && 'right' };

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
    }

    p {
      margin: 0;
      font-size: 25px;
      line-height: 60px;
      font-weight: 700;
      letter-spacing: 2pt;
      text-align: ${ ({ isLeft }) => isLeft ? 'left' : 'right' };
      /* text-align: ${ ({ isLeft }) => !isLeft && 'right' }; */
      margin-left: ${ ({ isLeft }) => !isLeft && '60px' };
      margin-right: ${ ({ isLeft }) => isLeft && '60px' };

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 46px;
        text-align: center;
        margin: 0;
      }
    }

    img {
      position: absolute;
      width: 5rem;
      height: auto;
      bottom: 0;
      right: ${ ({ isLeft }) => isLeft && '-3.5rem' };
      left: ${ ({ isLeft }) => !isLeft && '-4rem' };

      @media (max-width: 768px) {
        width: 3rem;
        left: -3rem;
      }
    }
  }
  

`
