import React from 'react'
import { Route as DomRoute, RouteProps } from 'react-router-dom'

export const Route: React.FC<RouteProps> = ({ children, ...props }) => {
  return(
    <DomRoute
      { ...props }
      render={() => {
        return (
          children
        )
      }}
    />
  )
}
