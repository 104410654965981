import React from 'react'
import { Route as DomRoute, RouteProps, Redirect } from 'react-router-dom'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { IStoreState } from '../../store/states'

interface Props extends DispatchProp<AnyAction>, RouteProps {
  token: string
}

const ProtectedRoute: React.FC<Props> = ({ children, ...props }) => {
  const { token } = props
  return(
    <DomRoute
      { ...props }
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

const mapStateToProps = ({ userInfo }: IStoreState) => ({
  token: userInfo.token
})

export default connect(mapStateToProps)(ProtectedRoute)
