import React, { useEffect, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { formatDistance, parseISO } from 'date-fns'
import { LiteralsService, UserService } from '../../../services'
import { images } from '../../../images'
import { useViewport } from '../../../hooks'
import { IPoolStats } from '../../../models'
import { StatsItem } from './StatsItem'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteProps {

}

const Stats: React.FC<IProps> = () => {
  const [data, setData] = useState<IPoolStats>()
  const animation = useAnimation()
  const [refInView, inView] = useInView({ threshold: 0.2 })
  const [showValue, setShowValue] = useState<boolean>(false)
  const { width } = useViewport()
  const breakpoint = 768

  const variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, delayChilden: 0.3, staggerChildren: 0.2 }
    },
    hidden: {
      y: 0,
      opacity: 0
    }
  }

  useEffect(() => {
    if (width > breakpoint) {
      setShowValue(true)
    }

    if (inView) {
      animation.start('visible')
    } else {
      // animation.start('hidden')
    }
  }, [inView, animation, showValue, width])

  useEffect(() => {
    getPoolStats()
  }, [])

  const getPoolStats = async () => {
    try {
      const response = await UserService.getPoolStats()
      if (response.data && response.data.statusCode === 200) {
        const res: IPoolStats = response.data.body
        const lastBlockTime = formatDistance(parseISO(res.lastBlockTime), new Date())
        console.log(lastBlockTime)
        const expectedTimeToWin = res.expectedTimeToWin.split(' ')
        res.lastBlockTime = lastBlockTime + ' ago'
        res.expectedTimeToWin = expectedTimeToWin[0].split('.')[0] + ' ' + expectedTimeToWin[1]
        setData(res)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return(
    <StyledSection>
      <motion.div ref={refInView} animate={animation} initial={'hidden'} variants={variants}>
        <h3>{LiteralsService.get('xchPoolStats')}</h3>
        <button onClick={() => setShowValue(value => !value)} className="showButton">
          {
            showValue ?
            <img src={images.iconDashboardArrowUp} /> :
            <img src={images.iconDashboardArrowDown} />
          }
        </button>
        {
          showValue &&
          <>
            <motion.div className="rowStats" variants={variants}>
              <StatsItem
                variants={variants} title={LiteralsService.get('activeFarmers')}
                iconName={'iconFarmer'} number={data?.farmers} isNumber isLeft topBorder
              />
              <StatsItem
                variants={variants} title={LiteralsService.get('totalPlots')}
                iconName={'iconHarddisk'} number={data?.totalPoolPlots} isNumber isLeft={false} topBorder
              />
            </motion.div>
            <motion.div className="rowStats" variants={variants}>
              <StatsItem
                variants={variants} title={LiteralsService.get('poolCapacity')}
                iconName={'iconStorage'} subTitle={data?.capacityString} isNumber={false} isLeft topBorder bottomBorder
              />
              <StatsItem
                variants={variants} title={LiteralsService.get('estimatedTimetoWin')}
                iconName={'iconChronometer'} subTitle={'<' + data?.expectedTimeToWin} isNumber={false} isLeft={false}
                topBorder bottomBorder
              />
            </motion.div>
            <motion.div className="rowStats" variants={variants}>
              <StatsItem
                variants={variants} title={LiteralsService.get('lastFarmedBlock')}
                iconName={'icon24Hours'} subTitle={data?.lastBlockTime} isNumber={false}
                isLeft topBorder bottomBorder
              />
              <StatsItem
                variants={variants} title={LiteralsService.get('totalNetspace')}
                iconName={'iconDatabase'} subTitle={data?.netspaceString} isNumber={false}
                isLeft={false} topBorder bottomBorder
              />
            </motion.div>
            <motion.div className="rowStats" variants={variants}>
              <StatsItem
                variants={variants} title={LiteralsService.get('netSpaceInPool')}
                iconName={'iconDoughnut'} subTitle={data && parseFloat(data.netspacePercent).toFixed(6) + '%'}
                isNumber={false} isLeft bottomBorder
              />
              <StatsItem
                variants={variants} title={LiteralsService.get('xchPrice')}
                iconName={'iconChiaUsd'} subTitle={data?.xchPriceUsd}
                isNumber={false} isLeft={false} bottomBorder
              />
            </motion.div>
          </>
        }
      </motion.div>
    </StyledSection>
  )
}


export default connect()(Stats)
