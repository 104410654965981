import styled from 'styled-components'

export default styled.section`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;
  background-color: ${ ({ theme }) => theme.colors.ebonyClay };

  header {
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 2rem;

    @media (max-width: 768px) {
      min-height: 64px;
      padding: 0 0.5rem;
    }
  }

  .logo {
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
      display: none;
    }

    img {
      height: 70px;
      width: auto;
      position: relative;
      margin-right: 2rem;
    }

    h2 {
      font-size: 24px;
      font-weight: 700;
      color: ${ ({ theme }) => theme.colors.white };
    }
  }

  .logo-mobile {
    display: none;
    flex-direction: row;
    align-items: center;

    @media (max-width: 768px) {
      display: flex;
    }

    img {
      width: 32px;
      height: 32px;
      margin-right: 1rem;

      @media (max-width: 768px) {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
    }

    h3 {
      color: ${ ({ theme }) => theme.colors.greenSpring };
      font-size: 24px;
      font-weight: 700;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    .showButton {
      border: none;
      background-color: ${ ({ theme }) => theme.colors.transparent };
      margin-top: 6px;
      
      img {
        margin: 0;
      }
    }
  }

  .navigation {
    /* flex-basis: 800px; */
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 768px) {
      .options {
        top: 24px;
      }
    }

    .menuItem {
      margin: 0;
      min-width: 140px;
      /* margin-left: 16px; */
      font-size: 16px;
      box-sizing: border-box;
      /* height: 100%; */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: all 0.1s ease;
      cursor: pointer;

      @media (max-width: 768px) {
        font-size: 14px;
        min-width: 120px;
      }

      img {
        position: absolute;
        left: -16px;
        width: 44px;
        height: auto;
        z-index: 5000;

        @media (max-width: 768px) {
          width: 32px;
        }
      }
      
      span {
        padding: 8px 24px;
        text-transform: uppercase;
        font-weight: normal;
        text-decoration: none;
        color: ${ ({ theme }) => theme.colors.black };

        @media (max-width: 768px) {
          padding: 4px 8px;
        }
      }

      background-color: ${ ({ theme }) => theme.colors.cerulean };

      &.active {
        /* border-bottom-color: ${ ({ theme }) => theme.colors.white }; */
        background-color: ${ ({ theme }) => theme.colors.cerulean }4D;
      }

      &:hover {
        background-color: ${ ({ theme }) => theme.colors.cerulean }50;
      }

    }

    .refreshButton {
      width: 44px;
      background-color: ${ ({ theme }) => theme.colors.transparent };
      margin-left: 2rem;
      box-shadow: none;

      @media (max-width: 768px) {
        width: 32px;
        margin-left: 0;

        img {
          margin-right: 0;
        }
      }
    }
  }

`
