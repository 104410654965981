import styled from 'styled-components'

export const FilterDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;

  @media screen and (min-width: 400px){
    margin-right: 70px;
  }

  .filterBtn {
    display: flex;
    cursor: pointer;

    p {
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 2;
      color: ${({ theme }) => theme.colors.greenSpring};
    }

    img {
      width: 20px;
      height: auto;
      margin-left: 8px;
    }
  }
`

export const CoinOptions = styled.div`
 width: 80px;
  margin-top: 45px;
  
  @media screen and (min-width: 30rem){
    width: 90px;
  }
  @media screen and (min-width: 40rem){
    width: 125px;
  }

  .toggle-btn {
    width: 29px;
    height: 16px;
    margin-bottom: 18px;
    background: #b8e4e8;
    border-radius: 0px 17px 17px 0px;
    border-left: 5px solid #b8e4e8;
    cursor: pointer;
    padding: 5px 14px;
    opacity: 0.5;
    
    @media screen and (min-width:40rem){
      width: 49px;
    height: 19px;
    padding-right:25px ;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .toggle-active {
    opacity: 1;
    border-left: 5px solid #28f19c;
  }



.hdd-logo{
  margin-left:0;
}
`

export const StyledDiv = styled.div`
  margin-top: 12px;

  .cardGroup {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;

    @media screen and (min-width: 1060px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 1335px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: 1612px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }


`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .goBackIcon {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 7px;
    top: 6px;
    cursor: pointer;
  }

  .checkboxGroup {
    display: flex;
    flex-direction: column;
    height: 425px;
    overflow-y: auto;
    margin: 62px 20px 0;
  }

  .checkboxGroup::-webkit-scrollbar {
    width: 5px;
  }

  .checkboxGroup::-webkit-scrollbar-track {
    background: #223149;
    border: 1px solid #158368;
    box-sizing: border-box;
    border-radius: 7px;
  }

  .checkboxGroup::-webkit-scrollbar-thumb {
    background: #28f19c;
    border-radius: 7px;
  }
`
