import React from 'react'
import styled, { keyframes } from 'styled-components'

interface Props {
  checked: boolean
}

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`

const Label = styled.label`
  position: relative;
  display: inline-block;
  margin-left: 27px;
  margin-bottom: 18px;
  padding-left: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #029DF5;
`
const rotate = keyframes`
  from {
    opacity: 0;
    transform: rotate(45deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`

const Indicator = styled.div<Props>`
  width: 22px;
  height: ${ ({ checked }) => checked ? '19px' : '22px' };
  cursor: pointer;
  background: #131C29;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: -1.6em;
  border-top: ${ ({ checked }) => checked ? '2px solid #29f19c' : 'none' };
  border-bottom: ${ ({ checked }) => checked ? '2px solid #0498ec' : 'none' };
  background-image: ${ ({ checked }) => checked
    ? 'linear-gradient(#29f19c, #0498ec), linear-gradient(#29f19c, #0498ec)'
    : 'none'
  };
  background-size: ${ ({ checked }) => checked ? '2px 100%' : 'inherit' };
  background-position: ${ ({ checked }) => checked ? '0 0,100% 0' : 'inherit' };
  background-repeat: ${ ({ checked }) => checked ? 'no-repeat' : 'inherit' };

  ${Input}:not(:disabled):checked & {
    background: #d1d1d1;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 1px;
    left: 7px;
    width: 4px;
    height: 13px;
    border: solid #28F19C;
    border-width: 0 2px 2px 0;
    animation-name: ${rotate};
    animation-fill-mode: forwards;
  }
`

const Checkbox = (props: CheckboxProps) => {
  return (
    <Label htmlFor={props.id}>
      {props.label}
      <Input
        id={props.id}
        type="checkbox"
        name={props.name}
        checked={props.checked}
        onChange={props.onChange}
      />
      <Indicator checked={ props.checked } />
    </Label>
  )
}

export default Checkbox

export interface CheckboxProps {
  checked: boolean
  onChange: (target: any) => void
  name?: string
  id?: string
  label?: string
  active: boolean
}
