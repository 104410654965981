import React, { useState } from 'react'
import { images } from '../../images'
import { StyledArrow, StyledTooltipContainer, StyledTooltipContent, StyledTooltipText } from './styles'

export const Tooltip: React.FC = ({ children }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const content = isTooltipVisible
    ? (
      <>
        <StyledTooltipContent>
          <div onMouseOver={ () => setIsTooltipVisible(true) }>
            <StyledTooltipText>
              { children }
            </StyledTooltipText>
          </div>
        </StyledTooltipContent>
        <StyledArrow />
      </>
      )
    : null

  return (
    <>
      <StyledTooltipContainer>
        <div
          onMouseOver={ () => setIsTooltipVisible(true) }
          onMouseOut={ () => setIsTooltipVisible(false) }
        >
          <img src={ images['iconInfo'] } alt="Info"/>
        </div>
      </StyledTooltipContainer>
      { content }
    </>
  )
}
