import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { images } from '../../images'
import { IMenuOption } from '../../models'
import { urls } from '../../routing'
import { LiteralsService } from '../../services'
import { openSignInPopupAction, signOutAction } from '../../store/actions'
import { IStoreState } from '../../store/states'
import { Dropdown } from './../../components'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  username: string
  token: string
  offsetYRoadmap: number
}

interface IState {
  showMenu: boolean
  showMarketPlace: boolean
  showAbout: boolean
  dropdown: boolean
}

class Menu extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      showMenu: false,
      showMarketPlace: false,
      showAbout: false,
      dropdown: false
    }
  }
  private get marketPlaceOptions(): IMenuOption[] {
    const { history } = this.props
    return [
      {
        icon: null,
        label: LiteralsService.get('chiaXch').toUpperCase(),
        action: () => {
          history.push(urls.comingSoon)
        }
      }
    ]
  }

  private get aboutOptions(): IMenuOption[] {
    const { offsetYRoadmap } = this.props
    return [
      {
        icon: null,
        label: LiteralsService.get('whitePaper').toUpperCase(),
        action: () => {
          console.log('white paper')
        }
      },
      {
        icon: null,
        label: LiteralsService.get('roadMap').toUpperCase(),
        action: () => {
          window.scrollTo({ top: offsetYRoadmap, behavior: 'smooth' })
        }
      },
      {
        icon: null,
        label: LiteralsService.get('documentation').toUpperCase(),
        action: () => {
          window.open('https://supermine.readthedocs.io/en/latest/', '_blank')
        }
      }
    ]
  }

  public render(): ReactNode {
    const { token, username } = this.props
    return (
      <StyledSection showMenu={this.state.showMenu}>
        <header>
          <div className="logo">
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <Link to={urls.home}>
                <img src={images['logo']} alt="supermine" />
              </Link>
              <span>{LiteralsService.get('supermine').toUpperCase()}</span>
            </div>
            <button className="menu-button" onClick={() => this.setState({ showMenu: !this.state.showMenu })}>
              <img src={this.state.showMenu ? images.iconExitMobile : images.iconMenuMobile} />
            </button>
          </div>
          <div className="navigation">
            <Link to={urls.dashboard} className={'menuItem'}>
              {LiteralsService.get('dashboard').toUpperCase()}
            </Link>
            <Dropdown options={this.marketPlaceOptions}
            >
              <p className="menuItem">
                <span>{LiteralsService.get('marketPlace').toUpperCase()}</span>
              </p>
            </Dropdown>

            <Dropdown options={this.aboutOptions}>
              <p className="menuItem">
                <span>{LiteralsService.get('about').toUpperCase()}</span>
              </p>
            </Dropdown>
            <button onClick={username && token ? this.handleLogout : this.handleSignIn} className={'menuItem'}>
              {username && token
                ? LiteralsService.get('logout').toUpperCase()
                : LiteralsService.get('login').toUpperCase()}
            </button>
          </div>
          <div className="navigation-mobile">
            {this.state.showMenu && (
              <>
                <Link to={urls.dashboard} className={'mobile-menu-item'}>
                  {LiteralsService.get('dashboard').toUpperCase()}
                </Link>
                <button
                  className={'mobile-menu-item'}
                  onClick={() => this.setState({ showMarketPlace: !this.state.showMarketPlace })}
                >
                  <p>
                    {LiteralsService.get('marketPlace').toUpperCase()}
                    <img src={images.iconArrowDownMobile} style={{ width: '24px', height: '16px' }} />
                  </p>
                </button>
                {this.state.showMarketPlace &&
                  this.marketPlaceOptions.map((m) => (
                    <button key={m.label} onClick={m.action} className={'mobile-menu-sub-item'}>
                      {m.label}
                    </button>
                  ))}
                <button
                  className={'mobile-menu-item'}
                  onClick={() => this.setState({ showAbout: !this.state.showAbout })}
                >
                  <p>
                    {LiteralsService.get('about').toUpperCase()}
                    <img src={images.iconArrowDownMobile} style={{ width: '24px', height: '16px' }} />
                  </p>
                </button>
                {this.state.showAbout &&
                  this.aboutOptions.map((m) => (
                    <button key={m.label} onClick={m.action} className={'mobile-menu-sub-item'}>
                      {m.label}
                    </button>
                  ))}
                <button
                  onClick={username && token
                    ? this.handleLogout
                    : this.handleSignIn} className={'mobile-menu-item'}
                >
                  {username && token
                    ? LiteralsService.get('logout').toUpperCase()
                    : LiteralsService.get('login').toUpperCase() + '/' + LiteralsService.get('register').toUpperCase()}
                </button>
                <button onClick={this.handleLanguage} className={'mobile-menu-item'}>
                  {LiteralsService.get('language').toUpperCase()}
                </button>
              </>
            )}
          </div>
        </header>
      </StyledSection>
    )
  }

  private handleSignIn = () => this.props.dispatch(openSignInPopupAction(true))
  private handleLogout = () => this.props.dispatch(signOutAction())
  private handleLanguage = () => {
    console.log('handleLanguage')
  }
}

const mapStateToProps = ({ userInfo }: IStoreState) => ({
  username: userInfo.username,
  token: userInfo.token,
  offsetYRoadmap: userInfo.offsetYRoadmap
})

export default withRouter(connect(mapStateToProps)(Menu))
