import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Page } from '../../components'
import { DashboardCards, DashboardFarmers } from '../../containers'
import { LiteralsService } from '../../services'
import { images } from '../../images'
import StyledArticle from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
}

interface IState {
  selectedCoin: string
}

class Dashboard extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      selectedCoin: 'xch'
    }
  }

  public render(): ReactNode {
    return (
      <Page style={{flex: 1}}>
        <StyledArticle>
          <header>
            <img src={images.iconDashboard} alt="Header Logo" />
            <h3>{LiteralsService.get('dashboard', true)}</h3>
          </header>
          <DashboardCards selectCoinHandler={ this.selectCoinHandler } />
          <DashboardFarmers selectedCoin={ this.state.selectedCoin } />
        </StyledArticle>
      </Page>
    )
  }

  private selectCoinHandler = (coin: string) => {
    this.setState({
      selectedCoin: coin
    })
  }
}

export default withRouter(connect()(Dashboard))
