import { ISettingState } from '../states'
import { SettingsActionTypes, SettingsTypeKeys } from '../types'

export const initialSettingsState: ISettingState = {
  language: 'en',
  username: '',
  rewardAddress: '',
  threshold: 0.05,
  error: false,
  isLoading: false,
  isUpdating: false
}

const settings = (
  state: ISettingState = initialSettingsState,
  action: SettingsActionTypes
): ISettingState => {
  switch (action.type) {
    case SettingsTypeKeys.CHANGE_LANGUAGE:
      return { ...state, language: action.payload }
    case SettingsTypeKeys.GETTING_SETTINGS:
      return { ...state, isLoading: true }
    case SettingsTypeKeys.GET_SETTINGS_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
        isLoading: false
      }
    case SettingsTypeKeys.SET_SETTINGS_USERNAME:
      return { ...state, username: action.payload }
    case SettingsTypeKeys.SET_SETTINGS_REWARD_ADDRESS:
      return { ...state, rewardAddress: action.payload }
    case SettingsTypeKeys.SET_SETTINGS_THERSHOLD:
      return { ...state, threshold: action.payload }
    case SettingsTypeKeys.UPDATING_SETTINGS:
      return { ...state, isUpdating: true }
    case SettingsTypeKeys.UPDATE_SETTINGS_SUCCESSFUL:
      return { ...state, isUpdating: false, error: false }
    case SettingsTypeKeys.UPDATE_SETTINGS_FAILED:
      return { ...state, isUpdating: false, error: true }
    default:
      return state
  }
}

export default settings
