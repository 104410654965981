import { capitalize } from '../helpers'
import { literals } from '../literals'

export type Language = keyof typeof literals
export type Literal = keyof typeof literals.en

class LiteralsService {
  private language: Language = 'en'

  constructor() {
    this.initialize()
  }

  public get(key: Literal, capitalized = false): string {
    const res = this.getKey(key)
    return capitalized ? capitalize(res) : res
  }

  public isLanguageActive(language: Language): boolean {
    return this.language === language
  }

  public setLanguage(language: Language): void {
    this.language = language
  }

  public getLanguage(): Language {
    return this.language
  }

  private async initialize(): Promise<void> {
    try {
      // const userAttributes = await authenticationService.getUserAttributes();
      // this.language = userAttributes.locale;
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error)
    }
  }

  private getKey(key: Literal): string {
    const localized = literals[this.language]
    if (localized && localized[key]) return localized[key]
    return literals.en[key] || key
  }
}

export default new LiteralsService()
