import { motion, Variants } from 'framer-motion'
import React from 'react'
import StyledSection from './styles'

interface IProps {
  title: string
  normalDescription: string
  normalDescription2?: string
  boldDescription: string
  variants?: Variants
}

export const ServiceItem: React.FC<IProps> = ({
  title,
  normalDescription,
  normalDescription2,
  boldDescription,
  variants
}) => {
  return (
    <StyledSection>
      <motion.div className="title" variants={variants}>
        <motion.h4 variants={variants}>{title}</motion.h4>
      </motion.div>
      <motion.div className="details" variants={variants} >
        <motion.p className="normal" variants={variants}>{normalDescription}</motion.p>
        <motion.p className="normal" variants={variants}>{normalDescription2}</motion.p>
        <motion.p className="bold" variants={variants}>{boldDescription}</motion.p>
      </motion.div>
    </StyledSection>
  )
}
