import styled from 'styled-components'
import { images } from '../../images'

export default styled.section`
  display: flex;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  box-sizing: border-box;
  background-image: url(${images.clouds});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 10rem;

  div {
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;
    z-index: 2;

    h3 {
      width: 30rem;
      color: ${ ({ theme }) => theme.colors.darkGreen };
      font-weight: 700;
      font-size: 36pt;
      line-height: 50pt;
      margin-bottom: 0;
      letter-spacing: 2pt;
    }

    span {
      text-transform: uppercase;
      font-size: 18pt;
      font-weight: 400;
      color: ${ ({ theme }) => theme.colors.black };
      letter-spacing: 2pt;
      transition: fadein 1s;

    }

    button {
      color: ${ ({ theme }) => theme.colors.darkGreen };
      font-size: 24pt;
      font-weight: 700;
      letter-spacing: 2pt;
      background-color: ${ ({ theme }) => theme.colors.white };
      border: 0;
      border-radius: 12px;
      text-transform: uppercase;
      padding: 8px 16px;
      margin-top: 10rem;
      box-shadow: 1px 2px 2px 2px ${ ({ theme }) => theme.colors.black }30;
      cursor: pointer;
      opacity: 0;
      transition: opacity 1s;

      /* animation: fadein 1s ease-in 1s; */

      &:hover {
        box-shadow: 2px 3px 3px 3px ${ ({ theme }) => theme.colors.black }60;
      }

      &:active {
        box-shadow: 1px 3px 4px 4px ${ ({ theme }) => theme.colors.black }30;
      }

      &.visible {
        opacity: 1;
      }
    }

  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
