import { ComingSoon, Home, Team } from '../pages'
import { dashboardRoutes, dashboardUrls } from './dashboard'

export const urls = {
  ...dashboardUrls,
  comingSoon: '/coming-soon',
  team: '/team',
  login: '/login',
  register: '/register',
  home: '/'
}

export const routes = [
  ...dashboardRoutes,
  { content: ComingSoon, url: urls.comingSoon, isAuthenticated: false, isMenuVisible: false },
  { content: Team, url: urls.team, isAuthenticated: false },
  { content: Home, url: urls.login, isAuthenticated: false },
  { content: Home, url: urls.register, isAuthenticated: false },
  { content: Home, url: urls.home, isAuthenticated: false }
  // { content: Splash, url: urls.splash, isAuthenticated: false }
]
