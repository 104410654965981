import styled from 'styled-components'

export default styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  min-width: 320px;
  box-sizing: border-box;
  background-color: ${ ({ theme }) => theme.colors.cloudBurst };

  @media (max-width: 768px) {
    position: absolute;
    min-height: 100%;
    z-index: 100;
  }

  .title {
    color: ${ ({ theme }) => theme.colors.greenSpring };
    background-color: ${ ({ theme }) => theme.colors.transparent };
    margin: 0;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    border-top: 2px solid ${ ({ theme }) => theme.colors.greenSpring };
    border-bottom: 2px solid ${ ({ theme }) => theme.colors.greenSpring };
  }

  .iconButton {
    text-align: left;
    justify-content: flex-start;
    padding: 8px 24px;
    color: ${ ({ theme }) => theme.colors.white };
    background-color: ${ ({ theme }) => theme.colors.transparent };
    box-shadow: none;
    font-size: 16px;
    font-weight: 700;
    transition: all 0.2s ease;

    img {
      margin-right: 1rem;
    }

    &:hover {
      color: ${ ({ theme }) => theme.colors.white }50;
    }
  }

`
