import React, { CSSProperties, PureComponent, ReactNode } from 'react'
import StyledArticle from './styles'

interface IProps {
  isHidden?: boolean
  className?: string
  style?: CSSProperties
}

class Page extends PureComponent<IProps> {
  public render(): ReactNode {
    const { children, style, className } = this.props
    return (
      <StyledArticle style={ style } className={ className || '' }>
       { children }
      </StyledArticle>
    )
  }
}

export default Page
