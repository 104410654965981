import styled from 'styled-components'

export default styled.section`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: ${ ({ theme }) => theme.colors.transparent };

  .aboutUs {
    margin: 0 auto;
    padding-top: 5rem;
    padding-bottom: 2rem;
    width: 100%;
    max-width: 100rem;
    display: flex;
    flex-direction: row;
    color: ${ ({ theme }) => theme.colors.white };
    text-align: center;

    @media (max-width: 450px) {
      flex-direction: column;
    }

    h4 {
      font-size: 16pt;
    }

    p, a {
      font-size: 12pt;
      line-height: 20pt;
      letter-spacing: 1pt;
      font-weight: 400;
      color: ${ ({ theme }) => theme.colors.white };

      @media (max-width: 450px) {
        font-size: 14px;
        letter-spacing: 0.5px;
      }
    }

    .about {
      flex: 1;
      flex-direction: column;

      p {
        margin-left: 3vw;
        margin-right: 3vw;
      }
    }

    .contact {
      flex: 1;
      flex-direction: column;
    }
  }

  .copyright {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: ${ ({ theme }) => theme.colors.black };

    .content {
      display: flex;
      flex: 1;
      flex-direction: row;
      max-width: 100rem;
      margin: 16px auto;
      justify-content: space-between;
      padding: 0 2rem;

      @media (max-width: 450px) {
        flex-direction: column;
      }

      p {
        margin: 0;
        color: ${ ({ theme }) => theme.colors.white };
        font-size: 16px;
        font-weight: 400;
        font-family: ${ ({ theme }) => theme.fontMyriad };

        @media (max-width: 450px) {
          line-height: 32px;
        }
      }

    }
  }
`
