import styled from 'styled-components'

export default styled.article`
  /* max-width: 80%; */
  min-height: calc(100vh - 80px);
  /* min-height: 80vh; */
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${ ({ theme }) => theme.colors.transparent };

  @media (max-width: 768px) {
    min-height: calc(100vh - 64px);
  }

  button.floating-button {
    position: fixed;
    bottom: 60px;
    left: 0;
    right: 0;
    margin: auto;
    width: max-content;
    padding: 0 20px;
  }

  &.authenticated {
    min-height: calc(100vh - 80px);

    .spinner-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`
