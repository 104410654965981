import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { LiteralsService } from '../../services'
import { IStoreState } from '../../store/states'
import {
  clearSignInErrorAction,
  openSignInPopupAction,
  // openSignUpPopupAction,
  resetRecoverPasswordFieldsAction,
  setSignInPasswordAction,
  setSignInUsernameAction,
  signInAction
} from '../../store/actions'
import { Modal, ValidatedInput, Button } from '../../components'
import { isPasswordValid, isUsernameValid } from '../../helpers'
import { images } from '../../images'
import StyledArticle from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  isOpen: boolean
  username: string
  password: string
  error: boolean
  loading: boolean
  token: string
}

class Login extends PureComponent<IProps> {

  private get isUsernameValid(): boolean {
    return isUsernameValid(this.props.username)
  }

  private get isPasswordValid(): boolean {
    return isPasswordValid(this.props.password)
  }

  private get modalContent(): ReactNode {
    const { username, password, error } = this.props

    return (
      <StyledArticle>
        <form onSubmit={ e => e.preventDefault() }>
          <header>
            <h3>
            { LiteralsService.get('login', true).toUpperCase() }
            </h3>
          </header>
          <section className="content" style={{ marginTop: '20px' }}>
            <ValidatedInput
              name="username"
              value={ username }
              label={ LiteralsService.get('usernameOrEmail', true).toUpperCase() }
              onChange={ this.onUsernameChange }
              error={ !this.isUsernameValid || error }
            />
            <ValidatedInput
              name="password"
              value={ password }
              label={ LiteralsService.get('password', true).toUpperCase() }
              onChange={ this.onPasswordChange }
              error={ !this.isPasswordValid || error }
              isPassword
            />
            <div className="link">
              <span onClick={ this.goToRecoverPassword }>
                { LiteralsService.get('forgottenPassword', true).toUpperCase() }
              </span>
            </div>
          </section>
          <section className="actions">
            <input type={'image'} src={images.signInGoogle} onClick={this.onSigninGoogle} />
            <Button
              onPress={ this.onLogin }
              title={ LiteralsService.get('login', true).toUpperCase() }
              styles={{ width: '250px', height: '50px', marginTop: '35px' }}
              filled
            />
            <div className="link">
              {/* <span onClick={ this.goToRecoverPassword }> */}
              <span>
                {/* { LiteralsService.get('dontHaveAccount', true).toUpperCase() } */}
                { LiteralsService.get('registrationCurrentlyClosed', true) }
              </span>
            </div>
            {/* <Button
              onPress={ this.onRegister }
              title={ LiteralsService.get('register', true).toUpperCase() }
              styles={{ width: '200px', marginTop: '10px' }}
              filled
            /> */}
          </section>
        </form>
      </StyledArticle>
    )
  }

  public render(): ReactNode {
    const { isOpen } = this.props
    return (
      <Modal className="small" isHidden={ !isOpen } onOverlayClick={ this.handleClose }>
        { this.modalContent }
      </Modal>
    )
  }

  private handleClose = () => this.props.dispatch(openSignInPopupAction(false))

  private onUsernameChange = (username: string) => {
    const { error, dispatch } = this.props

    if (error) dispatch(clearSignInErrorAction())
    dispatch(setSignInUsernameAction(username))
  }

  private onPasswordChange = (password: string) => {
    const { error, dispatch } = this.props

    if (error) dispatch(clearSignInErrorAction())
    dispatch(setSignInPasswordAction(password))
  }

  private goToRecoverPassword = () => {
    const { dispatch } = this.props
    dispatch(resetRecoverPasswordFieldsAction())
  }

  private onSigninGoogle = () => {
    console.log('onSigninGoogle')
  }

  private onLogin = () => {
    const { dispatch } = this.props
    dispatch(signInAction())
  }

  private onRegister = () => {
    // const { dispatch } = this.props

    this.handleClose()
    // dispatch(openSignUpPopupAction(true))
  }
}

const mapStateToProps = ({ signIn, userInfo }: IStoreState) => ({
  isOpen: signIn.openSignInPopup,
  username: signIn.username,
  password: signIn.password,
  error: signIn.error,
  loading: signIn.isSigningIn,
  token: userInfo.token
})

export default withRouter(connect(mapStateToProps)(Login))
