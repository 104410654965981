import styled from 'styled-components'

export default styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-top: 8rem;
  max-width: 80%;

  @media (max-width: 768px) {
    padding-top: 100px;
  }

  div {
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    z-index: 2;

    h3 {
      width: 30rem;
      color: ${ ({ theme }) => theme.colors.darkGreen };
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
      margin-bottom: 1rem;
      letter-spacing: 2px;

      @media (max-width: 768px) {
        color: ${ ({ theme }) => theme.colors.black };
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        margin: 0;
        margin-top: 2rem;
        width: 100%;
        letter-spacing: 1px;
      }
    }

    span {
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 500;
      color: ${ ({ theme }) => theme.colors.black };
      letter-spacing: 2px;

      @media (max-width: 768px) {
        font-size: 16px;
        letter-spacing: 0.5px;
      }
    }
    
    .supported-protocol {
      margin: 0;
      margin-top: 4rem;
      margin-left: 2rem;
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 500;
      color: ${ ({ theme }) => theme.colors.black };
      letter-spacing: 2px;

      @media (max-width: 768px) {
        font-size: 24px;
        margin-top: 2rem;
        margin-left: 0;
        letter-spacing: 0.5px;
      }
    }

    .protocol-icon {
      margin: 0;
      margin-top: 1rem;
      margin-left: 4rem;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 500;
      color: ${ ({ theme }) => theme.colors.black };
      letter-spacing: 2px;
      display: flex;
      align-items: center;
      
      @media (max-width: 768px) {
        margin-left: 2rem;
        font-size: 16px;
        letter-spacing: 0px;
      }

      img {
        width: 100px;
        height: auto;
        margin-right: 2rem;

        @media (max-width: 768px) {
          margin-right: 1rem;
        }
      }
    }

    .registration-disabled {
      margin: 0;
      margin-top: 1rem;
      /* margin-left: 2rem; */
      font-size: 14px;
      font-weight: 600;
      color: ${ ({ theme }) => theme.colors.black };
      letter-spacing: 0.5px;
      display: flex;
      align-items: center;
    }

    button {
      margin-top: 3rem;
      margin-left: 0;
      color: ${ ({ theme }) => theme.colors.darkGreen };
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 2px;
      background-color: ${ ({ theme }) => theme.colors.white };
      border: 0;
      border-radius: 12px;
      text-transform: uppercase;
      padding: 12px 48px;
      box-shadow: 1px 2px 2px 2px ${ ({ theme }) => theme.colors.black }30;
      cursor: pointer;

      @media (max-width: 768px) {
        padding: 8px 20px;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 1px;
        margin-top: 2rem;
        width: 100%;
      }

      &:hover {
        box-shadow: 2px 3px 3px 3px ${ ({ theme }) => theme.colors.black }60;
      }

      &:active {
        box-shadow: 1px 3px 4px 4px ${ ({ theme }) => theme.colors.black }30;
      }

      &.visible {
        opacity: 1;
      }

    }

  }
`
