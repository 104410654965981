import styled from 'styled-components'
import { motion } from 'framer-motion'

export default styled(motion.span)`
  color: ${ ({ theme }) => theme.colors.black };
  font-family: ${ ({ theme }) => theme.fontFamily };
  font-weight: 400;
  font-size: 16px;

`

