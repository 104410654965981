import styled from 'styled-components'
import { Color } from '../../../models'

interface Props {
  backgroundColor: Color
  color?: Color
  activeColor?: Color
}

export default styled.li<Props>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  padding: 6px 8px;
  border-radius: 2px;
  cursor: pointer;
  color: ${ ({ theme, color }) => color ? color : theme.colors.greyDark };
  background-color: ${ ({ backgroundColor }) => backgroundColor };
  text-align: center;
  margin: 8px 0;

  .checkmark {
    display: inline-block;
    margin-right: 16px;
    /* justify-content: center;
    align-items: center; */

    img {
      margin: 0;
      opacity: 1;
    }

  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    opacity: 1;
  }

  span {
    /* font-family: ${ ({ theme }) => theme.fontFamily }; */
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    z-index: 2001;
    /* max-width: 20vw;
    width: 100%; */
    /* display: inline-block;
    position: relative; */
  }

  &:not(.disabled):hover {
    /* color: ${ ({ theme, activeColor }) => activeColor ? activeColor : theme.colors.black }; */
    color: ${ ({ theme, activeColor }) => activeColor ? activeColor : theme.colors.black };

    img {
      opacity: 0.7;
    }
    /* .checkmark {
      img {
        opacity: 0.5;
      }
    } */
  }

  &.disabled {
    user-select: none;
    pointer-events: none;
    background-color: ${ ({ theme }) => theme.colors.greyLight };
    color: ${ ({ theme }) => theme.colors.grey };
  }
`
