import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'

import changePassword from './change-password'
import newPasswordRequired from './new-password-required'
import recoverPassword from './recover-password'
import signIn from './sign-in'
import signUp from './sign-up'
import userInfo from './user-info'
import verification from './verification'
import setting from './setting'
import dashboard from './dashboard'
import notification from './notification'

const reducers = {
  userInfo,
  signUp,
  signIn,
  recoverPassword,
  verification,
  changePassword,
  newPasswordRequired,
  setting,
  dashboard,
  notification
}

/* Redux-Persist */
const rootReducer = persistCombineReducers({
  key: 'root',
  storage,
  whitelist:['userInfo']
}, reducers)

// const createRootReducer = () => combineReducers({
//   userInfo,
//   signUp,
//   signIn,
//   recoverPassword,
//   verification,
//   changePassword,
//   newPasswordRequired,
//   setting,
//   dashboard,
//   notification
// })

export default rootReducer
