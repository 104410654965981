import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { LiteralsService } from '../../../services'
import { IStoreState } from '../../../store/states'
import {
  openSignInPopupAction
} from '../../../store/actions'
import { Modal, ValidatedInput, Button } from '../../../components'
import StyledArticle from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  loginToken: string
  token: string
  isOpen: boolean
}

interface IState {
  name: string
}

class ChangeTokenModal extends PureComponent<IProps, IState> {

  constructor(props: IProps) {
    super(props)
    this.state = {
      name: ''
    }
  }

  private get modalContent(): ReactNode {
    const { name } = this.state

    return (
      <StyledArticle>
        <form onSubmit={ e => e.preventDefault() }>
          <header>
            <h3>
            { LiteralsService.get('changeTokenName', true).toUpperCase() }
            </h3>
          </header>
          <section className="content" style={{ marginTop: '20px' }}>
            <ValidatedInput
              name="username"
              value={ name }
              label={ LiteralsService.get('name', true).toUpperCase() }
              onChange={ this.onUsernameChange }
            />
          </section>
          <section className="actions">
            <Button
              onPress={ this.onLogin }
              title={ LiteralsService.get('change', true).toUpperCase() }
              styles={{ width: '250px', height: '50px', marginTop: '35px' }}
              filled
            />
          </section>
        </form>
      </StyledArticle>
    )
  }

  public render(): ReactNode {
    const { isOpen } = this.props
    return (
      <Modal className="small" isHidden={ !isOpen } onOverlayClick={ this.handleClose }>
        { this.modalContent }
      </Modal>
    )
  }

  private handleClose = () => this.props.dispatch(openSignInPopupAction(false))

  private onUsernameChange = (username: string) => {
    this.setState({name: username})
  }

  private onLogin = () => {
    // const { dispatch } = this.props
    // dispatch(signInAction())
  }

}

const mapStateToProps = ({ userInfo }: IStoreState) => ({
  loginToken: userInfo.token
})

export default withRouter(connect(mapStateToProps)(ChangeTokenModal))
