export enum DashboardTypeKeys {
  GET_DASHBOARD = 'GET_DASHBOARD',
  GETTING_DASHBOARD = 'GETTING_DASHBOARD',
  GET_DASHBOARD_SUCCESSFUL = 'GET_DASHBOARD_SUCCESSFUL',
  UPDATE_DASHBOARD = 'UPDATE_DASHBOARD',
  CREATE_FARMER_TOKEN = 'CREATE_FARMER_TOKEN',
  CREATING_FARMER_TOKEN = 'CREATING_FARMER_TOKEN',
  CREATED_FARMER_TOKEN_SUCCESSFULLY = 'CREATED_FARMER_TOKEN_SUCCESSFULLY',
  COPY_FARMER_TOKEN = 'COPY_FARMER_TOKEN',
  DELETE_FARMER_TOKEN = 'DELETE_FARMER_TOKEN',
  DELETED_FARMER_TOKEN_SUCCESSFULLY = 'DELETED_FARMER_TOKEN_SUCCESSFULLY',
  SHOW_MOBILE_MENU = 'SHOW_MOBILE_MENU',
  RENAME_FARMER_TOKEN = 'RENAME_FARMER_TOKEN',
  RENAMEING_FARMER_TOKEN = 'RENAMEING_FARMER_TOKEN',
  RENAME_FARMER_TOKEN_SUCCESSFULLY = 'RENAME_FARMER_TOKEN_SUCCESSFULLY'
}

export interface IShowMobileMenuAction {
  type: typeof DashboardTypeKeys.SHOW_MOBILE_MENU
  payload: boolean
}

export interface IDeletedFarmerTokenSuccessfullyAction {
  type: typeof DashboardTypeKeys.DELETED_FARMER_TOKEN_SUCCESSFULLY
}

export interface IDeleteFarmerTokenAction {
  type: typeof DashboardTypeKeys.DELETE_FARMER_TOKEN
}

export interface ICopyFarmerTokenAction {
  type: typeof DashboardTypeKeys.COPY_FARMER_TOKEN
  payload: string
}

export interface ICreateFarmerTokenAction {
  type: typeof DashboardTypeKeys.CREATE_FARMER_TOKEN
}

// export interface IrenameFarmerTokenAction {
//   type: typeof DashboardTypeKeys.RENAME_FARMER_TOKEN_ACTION
// }

export interface ICreatingFarmerTokenAction {
  type: typeof DashboardTypeKeys.CREATING_FARMER_TOKEN
  payload: boolean
}

export interface ICreatedFarmerTokenSuccessfullyAction {
  type: typeof DashboardTypeKeys.CREATED_FARMER_TOKEN_SUCCESSFULLY
  payload: any
}

export interface IRenameFarmerTokenSuccessfullyAction {
  type: typeof DashboardTypeKeys.RENAME_FARMER_TOKEN_SUCCESSFULLY
  payload: any
}

export interface SelectedFarmerToken {
  name: string
  token: string
}

export interface IRenamingFarmerTokenAction {
  type: typeof DashboardTypeKeys.RENAMEING_FARMER_TOKEN
  payload: SelectedFarmerToken
}

export interface IRenameFarmerTokenAction {
  type: typeof DashboardTypeKeys.RENAME_FARMER_TOKEN
  payload: boolean
}

export interface IGetDashboardAction {
  type: typeof DashboardTypeKeys.GET_DASHBOARD
}

export interface IGettingDashboardAction {
  type: typeof DashboardTypeKeys.GETTING_DASHBOARD
  payload: boolean
}

export interface IGetDashboardSuccessfulAction {
  type: typeof DashboardTypeKeys.GET_DASHBOARD_SUCCESSFUL
  payload: any
}

export interface IUpdateDashboardAction {
  type: typeof DashboardTypeKeys.UPDATE_DASHBOARD
}

export type DashboardActionTypes =
  | IShowMobileMenuAction
  | ICreateFarmerTokenAction
  | ICreatingFarmerTokenAction
  | ICreatedFarmerTokenSuccessfullyAction
  | IGetDashboardAction
  | IGettingDashboardAction
  | IGetDashboardSuccessfulAction
  | IUpdateDashboardAction
  | ICopyFarmerTokenAction
  | IDeleteFarmerTokenAction
  | IDeletedFarmerTokenSuccessfullyAction
  | IRenameFarmerTokenAction
  | IRenamingFarmerTokenAction
  | IRenameFarmerTokenSuccessfullyAction
