import styled from 'styled-components'

export default styled.article`

  form {
    .link {
      margin-top: 12px;
      text-align: right;

      span {
        color: ${ ({ theme }) => theme.colors.black };
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 15px;
        text-align: right;
        cursor: pointer;
      }
    }

    .actions {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .link {
        margin-top: 50px;

        span {
          font-size: 20px;
          font-weight: 400;
        }
      }

      input {
        margin-top: 30px;
        height: 50px;
        width: auto;
      }
    }

    .error-container {
      flex-direction: row;
      justify-content: center;
      margin-top: 25px;
      width: 100%;
      text-align: center;
      align-self: center;

      .error {
        color: ${ ({ theme }) => theme.colors.red };
        text-align: center;
      }
    }
  }

`
