import {
  DashboardTypeKeys,
  ICopyFarmerTokenAction,
  ICreatedFarmerTokenSuccessfullyAction,
  ICreateFarmerTokenAction,
  ICreatingFarmerTokenAction,
  IDeletedFarmerTokenSuccessfullyAction,
  IDeleteFarmerTokenAction,
  IGetDashboardAction,
  IGetDashboardSuccessfulAction,
  IGettingDashboardAction,
  IShowMobileMenuAction,
  IRenameFarmerTokenAction,
  IRenamingFarmerTokenAction,
  IRenameFarmerTokenSuccessfullyAction,
  SelectedFarmerToken
} from '../types'

export function showMobileMenuAction(payload: boolean): IShowMobileMenuAction {
  return { type: DashboardTypeKeys.SHOW_MOBILE_MENU, payload }
}

export function deleteFarmerTokenAction(): IDeleteFarmerTokenAction {
  return { type: DashboardTypeKeys.DELETE_FARMER_TOKEN }
}

export function deletedFarmerTokenSuccessfullyAction(): IDeletedFarmerTokenSuccessfullyAction {
  return { type: DashboardTypeKeys.DELETED_FARMER_TOKEN_SUCCESSFULLY }
}

export function copyFarmerTokenAction(payload: string): ICopyFarmerTokenAction {
  return { type: DashboardTypeKeys.COPY_FARMER_TOKEN, payload: payload }
}

export function createFarmerTokenAction(): ICreateFarmerTokenAction {
  return { type: DashboardTypeKeys.CREATE_FARMER_TOKEN }
}

export function creatingFarmerAction(payload: boolean): ICreatingFarmerTokenAction {
  return { type: DashboardTypeKeys.CREATING_FARMER_TOKEN, payload: payload }
}

export function createdFarmerTokenSuccessfullyAction(payload: any): ICreatedFarmerTokenSuccessfullyAction {
  return { type: DashboardTypeKeys.CREATED_FARMER_TOKEN_SUCCESSFULLY, payload: payload }
}

export function renameFarmerTokenAction(payload: boolean): IRenameFarmerTokenAction {
  return { type: DashboardTypeKeys.RENAME_FARMER_TOKEN, payload: payload }
}

export function renamingFarmerTokenAction(payload: SelectedFarmerToken): IRenamingFarmerTokenAction {
  return { type: DashboardTypeKeys.RENAMEING_FARMER_TOKEN, payload: payload }
}

export function RenamedFarmerTokenSuccessfullyAction(payload: any): IRenameFarmerTokenSuccessfullyAction {
  return { type: DashboardTypeKeys.RENAME_FARMER_TOKEN_SUCCESSFULLY, payload: payload }
}

export function getDashboardAction(): IGetDashboardAction {
  return { type: DashboardTypeKeys.GET_DASHBOARD }
}

export function gettingDashboardAction(payload: boolean): IGettingDashboardAction {
  return { type: DashboardTypeKeys.GETTING_DASHBOARD, payload: payload }
}

export function getDashboardSuccessfulAction(payload: any): IGetDashboardSuccessfulAction {
  return { type: DashboardTypeKeys.GET_DASHBOARD_SUCCESSFUL, payload: payload }
}
