import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Page } from '../../components'
import { LiteralsService } from '../../services'
import StyledSection from './styles'

interface Props extends DispatchProp<AnyAction>, RouteComponentProps {
}

interface States {
  width: number
  height: number
  offset: number
}

class Team extends PureComponent<Props, States> {

  constructor(props: Props) {
    super(props)
  }

  public render(): ReactNode {
    return (
      <Page style={{overflowY: 'hidden'}}>
        <StyledSection>
          <div>
            <h3>{LiteralsService.get('poolingWorld')}</h3>
            <p>
              <span>{LiteralsService.get('secure') + ', '}</span>
              <span>{LiteralsService.get('scalable') + ', '}</span>
              <span>{LiteralsService.get('profitable')}</span>
            </p>
            <button >{LiteralsService.get('joinThePool')}</button>
          </div>
        </StyledSection>
      </Page>
    )
  }
}

export default withRouter(connect()(Team))
