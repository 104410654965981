import React, { useEffect, useRef, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import Slider, { Settings } from 'react-slick'
import { LiteralsService } from '../../../services'
import { images } from '../../../images'
import { setPageOffsetRoadmap } from '../../../store/actions'
import { useViewport } from '../../../hooks'
import { RoadmapItem } from './RoadmapItem'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteProps {

}

const Roadmap: React.FC<IProps> = ({ ...props }) => {
  const refSection = useRef<HTMLElement>(null)

  const animation = useAnimation()
  const [refInView, inView] = useInView({ threshold: 0.3 })
  const [showValue, setShowValue] = useState(false)
  const { width } = useViewport()
  const breakpoint = 768

  const variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.3, delayChilden: 0.2, staggerChildren: 0.2 }
    },
    hidden: {
      y: 0,
      opacity: 0
    }
  }

  const hoverAnimation = {
    scale: 1.1, textShadow: '2px 4px 4px rgba(0, 0, 0, 0.3)'
  }

  const tapAnimation = {
    scale: 0.9, textShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)'
  }

  const refSlide = useRef<Slider>(null)

  const roadmapData = [
    {
      id: 1,
      title: 'Q3 2021',
      description : `
      <h5>${LiteralsService.get('mainFeatures')}</h5>
      <br>
      <p>${LiteralsService.get('unofficialChiaPoolingProtocal')}</p>
      <label>${LiteralsService.get('unofficialChiaPoolingProtocolDescription')}</label>
      `
      // <p>&nbsp&nbsp&nbsp&nbsp-&nbsp${LiteralsService.get('linuxAndWindowsSupport')}</p>
      // <p>&nbsp&nbsp&nbsp&nbsp-&nbsp${LiteralsService.get('dashboardToViewBlockRewards')}</p>
      // <p>${LiteralsService.get('officialChiaPoolingProtocal')}</p>
      // <p>&nbsp&nbsp&nbsp&nbsp-&nbsp${LiteralsService.get('poolServerStats')}</p>
      // <p>&nbsp&nbsp&nbsp&nbsp-&nbsp${LiteralsService.get('scalableFalutTolerantSecure')}</p>
      // <p>&nbsp&nbsp&nbsp&nbsp-&nbsp${LiteralsService.get('stabiltyBugfixesOfOurPlatform')}</p>
      // `
    },
    {
      id: 2,
      title: 'Q4 2021',
      description : `
      <h5>${LiteralsService.get('buildingOurEcosystem')}</h5>
      <br>
      <p>${LiteralsService.get('leaderboard')}</p>
      <p>${LiteralsService.get('referralSystem')}</p>
      <p>${LiteralsService.get('multipleLanugageSupport')}</p>
      <p>${LiteralsService.get('multipleRegion')}</p>
      <p>${LiteralsService.get('communityVoting')}</p>
      <p>${LiteralsService.get('darkMode')}</p>
      <p>${LiteralsService.get('farmerMarketplace')}</p>
      <p>&nbsp&nbsp&nbsp&nbsp-&nbsp${LiteralsService.get('putYourXchRewardsToUse')}</p>
      `
    },
    {
      id: 3,
      title: 'Q1 2022',
      description : `
      <h5>${LiteralsService.get('community')}</h5>
      <br>
      <p>- ${LiteralsService.get('communittyList')}</p>
      <p>- ${LiteralsService.get('votingImprovement')}</p>
      <p>- ${LiteralsService.get('newCoinListing')}</p>
      <p>- ${LiteralsService.get('proDashboard')}</p>
      `
    },
    {
      id: 4,
      title: 'Q2 2022',
      description : `
      <h5>${LiteralsService.get('rewards')}</h5>
      <br>
      <p>- ${LiteralsService.get('airdropPlatformForOurUsers')}</p>
      <p>- ${LiteralsService.get('educationPlatform')}</p>
      <p>- ${LiteralsService.get('partnershipsWithotherEcosystem')}</p>
      `
    },
    {
      id: 5,
      title: 'Q3 2022',
      description : `
      <h5>${LiteralsService.get('future')}</h5>
      <br>
      <p>- ${LiteralsService.get('introductionToNew')}</p>
      <p>- ${LiteralsService.get('roadmapPlanAndExpansion')}</p>
      <br>
      <p>- ${LiteralsService.get('defiDiscovery')}</p>
      <br>
      <p>- ${LiteralsService.get('nftDiscovery')}</p>
      `
    }
  ]

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    centerMode: false,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1
  }

  useEffect(() => {
    if (width > breakpoint) {
      setShowValue(true)
    }
    if (inView) {
      animation.start('visible')
    } else {
      // animation.start('hidden')
    }
  }, [inView, animation, showValue, width])

  useEffect(() => {
    const { dispatch } = props
    if (refSection.current) {
      dispatch(setPageOffsetRoadmap(refSection.current?.getBoundingClientRect().y))
    }
  }, [props])

  const goToIndex = (index: number) => {
    if (!refSlide.current) return
    refSlide.current?.slickGoTo(index)
  }

  return(
    <StyledSection ref={refSection}>
      <motion.div className="title" ref={refInView} animate={animation} initial={'hidden'} variants={variants}>
        <motion.h3 variants={variants}>{LiteralsService.get('roadMap')}</motion.h3>
        <button onClick={() => setShowValue(value => !value)} className="showButton">
          {
            showValue ?
            <img src={images.iconDashboardArrowUp} /> :
            <img src={images.iconDashboardArrowDown} />
          }
        </button>
        {
          showValue &&
          <>
            <motion.div className="iceberg" variants={variants}>
              <motion.img src={images.iceberg} alt="supermine roadmap"  variants={variants}/>
              <motion.button
                id="q3-2021" variants={variants} onClick={() => goToIndex(0)}
                whileHover={hoverAnimation} whileTap={tapAnimation}
              >
                {'Q3 2021'}
              </motion.button>
              <motion.button
                id="q4-2021" variants={variants} onClick={() => goToIndex(1)}
                whileHover={hoverAnimation} whileTap={tapAnimation}
              >
                {'Q4 2021'}
              </motion.button>
              <motion.button
                id="q1-2022" variants={variants} onClick={() => goToIndex(2)}
                whileHover={hoverAnimation} whileTap={tapAnimation}
              >
                {'Q1 2022'}
              </motion.button>
              <motion.button
                id="q2-2022" variants={variants} onClick={() => goToIndex(3)}
                whileHover={hoverAnimation} whileTap={tapAnimation}
              >
                {'Q2 2022'}
              </motion.button>
              <motion.button
                id="q3-2022" variants={variants} onClick={() => goToIndex(4)}
                whileHover={hoverAnimation} whileTap={tapAnimation}
              >
                {'Q3 2022'}
              </motion.button>
            </motion.div>
            <motion.div className="roadmap-desktop">
              <Slider {...settings} ref={refSlide}>
                {
                  roadmapData.map((item) => {
                    return <RoadmapItem title={item.title} description={item.description} key={item.id} />
                  })
                }
              </Slider>
            </motion.div>
            <motion.div className={'roadmap-mobile'} variants={variants}>
              {
                roadmapData.map((item) => {
                  return (
                    <RoadmapItem
                      title={item.title}
                      description={item.description}
                      key={item.id}
                      variants={variants}
                    />
                  )
                })
              }
            </motion.div>
          </>
        }
      </motion.div>
    </StyledSection>
  )
}

export default connect()(Roadmap)
