import styled from 'styled-components'

export default styled.section`
  display: flex;
  /* min-height: 50vh; */
  width: 100vw;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-top: 18rem;

  @media (max-width: 768px) {
    margin-top: 4rem;
  }

  div {
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;
    z-index: 2;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */

    @media (max-width: 768px) {
      text-align: center;
    }

    .showButton {
      border: none;
      background-color: ${ ({ theme }) => theme.colors.transparent };
      display: none;

      @media (max-width: 768px) {
        display: inline-block;
      }
    }

    h3 {
      text-align: center;
      color: ${ ({ theme }) => theme.colors.white };
      font-size: 72px;
      font-weight: 700;
      line-height: 80px;
      letter-spacing: 2px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
    }

    .items {
      flex-direction: row;
      justify-content: space-evenly;

      @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 0;
        align-items: center;
      }
    }
  }

`
