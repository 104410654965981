import React, { useEffect } from 'react'
import { INotification } from '../../models'
import StyledDiv from './styles'

interface IProps {
  notification: INotification
  resetScreenParams: () => void
}

export const Notification: React.FC<IProps> = ({ notification, resetScreenParams }) => {
  const isErrorNotification = notification && notification.isError

  useEffect(() => {
    setTimeout(() => {
      resetScreenParams()
    }, 5000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!notification) return null
  return (
    <StyledDiv
      className={ isErrorNotification ? 'error' : '' }
      style={{ animationDelay: isErrorNotification ? '3700ms' : '2700ms' }}
    >
      <p>{ notification.label }</p>
    </StyledDiv>
  )
}
