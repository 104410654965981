import styled from 'styled-components'

export default styled.div`
  width: 234px;
  height: 110px;
  position: relative;
  padding: 7px 14px;
  border-radius: 10px;
  background-color: ${ ({ theme }) => theme.colors.cloudBurst };

  .cardTitle {
    margin: 0;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: ${ ({ theme }) => theme.colors.azure };
  }

  .cardValue {
    margin-right: 10px;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: ${ ({ theme }) => theme.colors.white };
  }

  .unit {
    color: #b5cff6;
  }
`

