import styled from 'styled-components'

export default styled.article`
  width: 100vw;
  height: 100vh;
  background-color: ${ ({ theme }) => theme.colors.mirage };
  background-image: url('/assets/images/coming-soon-background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .stars {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    h2 {
      color: ${ ({ theme }) => theme.colors.greenSpring };
      font-size: 72px;
      margin-top: 10vh;
      margin-left: 5vw;
      letter-spacing: 2px;
    }

    .bear {
      position: absolute;
      width: 100%;
      height: auto;
      left: 0;
      bottom: 0;
    }

    .logo {
      position: absolute;
      top: 2vw;
      right: 2vw;
      width: 120px;
      height: 120px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .stars .container {
    position: absolute;
    animation: stars linear infinite;
  }

  .stars .container .star {
    animation: twinkle linear infinite;
    border-radius: 100%;
    transform: translateZ(0);
  }

  @-moz-keyframes stars {
    0% {
      transform: translateY(70vh) translateZ(0);
    }
    100% {
      transform: translateY(-10vh) translateZ(0);
    }
  }
  @-webkit-keyframes stars {
    0% {
      transform: translateY(70vh) translateZ(0);
    }
    100% {
      transform: translateY(-10vh) translateZ(0);
    }
  }
  @-o-keyframes stars {
    0% {
      transform: translateY(70vh) translateZ(0);
    }
    100% {
      transform: translateY(-10vh) translateZ(0);
    }
  }
  @keyframes stars {
    0% {
      transform: translateY(70vh) translateZ(0);
    }
    100% {
      transform: translateY(-10vh) translateZ(0);
    }
  }
  @-moz-keyframes twinkle {
    0%, 80%, 100% {
      opacity: 0.7;
      box-shadow: 0 0 0 #fff, 0 0 0 #fff;
    }
    95% {
      opacity: 1;
      box-shadow: 0 0 2px #fff, 0 0 4px #fff;
    }
  }
  @-webkit-keyframes twinkle {
    0%, 80%, 100% {
      opacity: 0.7;
      box-shadow: 0 0 0 #fff, 0 0 0 #fff;
    }
    95% {
      opacity: 1;
      box-shadow: 0 0 2px #fff, 0 0 4px #fff;
    }
  }
  @-o-keyframes twinkle {
    0%, 80%, 100% {
      opacity: 0.7;
      box-shadow: 0 0 0 #fff, 0 0 0 #fff;
    }
    95% {
      opacity: 1;
      box-shadow: 0 0 2px #fff, 0 0 4px #fff;
    }
  }
  @keyframes twinkle {
    0%, 80%, 100% {
      opacity: 0.7;
      box-shadow: 0 0 0 #fff, 0 0 0 #fff;
    }
    95% {
      opacity: 1;
      box-shadow: 0 0 2px #fff, 0 0 4px #fff;
    }
  }

`
