import React from 'react'
import parse from 'html-react-parser'
import { motion, Variants } from 'framer-motion'
import StyledSection from './styles'

interface IProps {
  title: string
  description: string
  variants?: Variants
}

export const RoadmapItem: React.FC<IProps> = ({
  title,
  description,
  variants
}) => {
  return (
    <StyledSection>
      <motion.div className="title" variants={variants}>
        <motion.h4 variants={variants}>{title}</motion.h4>
      </motion.div>
      <motion.div className="details" variants={variants}>
        {
          parse(description)
        }
      </motion.div>
    </StyledSection>
  )
}
