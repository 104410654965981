import styled from 'styled-components'

export default styled.article`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${ ({ theme }) => theme.colors.mirage };
  padding: 2rem 4rem;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 768px) {
      display: none;
    }

    img {
      width: 32px;
      height: 32px;
      margin-right: 1rem;
    }

    h3 {
      color: ${ ({ theme }) => theme.colors.greenSpring };
      font-size: 24px;
      font-weight: 700;
      margin: 0;
    }
  }

  form {
    background-color: ${ ({ theme }) => theme.colors.cloudBurst };
    border-radius: 8px;
    padding: 12px 16px;
    margin-top: 24px;
    max-width: 50vw;

    @media (max-width: 768px) {
      max-width: 100vw;
    }

    input[type="text"] {
      box-shadow: none;
      border-radius: 4px;
      background-color: ${ ({ theme }) => theme.colors.eastBay };
      color: ${ ({ theme }) => theme.colors.white };
    }

    .actions {
      display: flex;
      justify-content: flex-end;
    }
  }

`
