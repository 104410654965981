import { Dashboard, Settings } from '../pages'

export const dashboardUrls = {
  dashboard: '/dashboard',
  settings: '/settings'
}

export const dashboardRoutes = [
  { content: Dashboard, url: dashboardUrls.dashboard, isAuthenticated: true, sideMenu: true },
  { content: Settings, url: dashboardUrls.settings, isAuthenticated: true, sideMenu: true }
]
