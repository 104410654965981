import styled from 'styled-components'

export default styled.section`
  display: flex;
  /* min-height: 50vh; */
  width: 100vw;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-top: 18rem;

  @media (max-width: 768px) {
    margin-top: 4rem;
  }

  .title {
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;
    z-index: 2;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      text-align: center;
    }

    .showButton {
      border: none;
      background-color: ${ ({ theme }) => theme.colors.transparent };
      display: none;

      @media (max-width: 768px) {
        display: inline-block;
      }
    }

    h3 {
      text-align: center;
      color: ${ ({ theme }) => theme.colors.white };
      font-size: 72px;
      font-weight: 700;
      line-height: 80px;
      letter-spacing: 2px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
    }
  }

  .iceberg {
    position: relative;
    width: 100%;
    min-height: 420px;
    overflow: hidden;
    padding: 5rem 0;

    @media (max-width: 768px) {
      display: none;
    }

    img {
      display: block;
      position: absolute;
      min-height: 300px;
      height: auto;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
    }

    button {
      background-color: ${ ({ theme }) => theme.colors.transparent };
      color: ${ ({ theme }) => theme.colors.greenText };
      font-size: 32pt;
      font-weight: 700;
      letter-spacing: 2pt;
      position: relative;
      border: none;
      height: 120px;
    }

    #q3-2021 {
      left: 6rem;
      top: 23rem;
    }

    #q4-2021 {
      left: 5rem;
      top: -3.5rem;
    }

    #q1-2022 {
      left: 13rem;
      top: 20rem;
    }

    #q2-2022 {
      left: 20rem;
      top: -3rem;
    }

    #q3-2022 {
      left: 20rem;
      top: 20rem;
    }

    .roadmap-mobile {
      display: none;

    }
  }

  .roadmap-desktop {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .roadmap-mobile {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      display: flex;
      text-align: left;
      justify-content: center;
      .title {
        text-align: left;
      }
    }
  }

`
