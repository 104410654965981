import { RouteComponentProps } from 'react-router-dom'
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects'
import { urls } from '../../routing'
import { AuthenticationService, LiteralsService } from '../../services'
import { LoginResponse } from '../../models'
import {
  checkSessionAction,
  clearSignInFieldsAction,
  openSignInPopupAction,
  setIsSigningInAction,
  setNotificationAction,
  setUserInfoAction,
  setUserInfoTokenAction,
  setUserInfoUsernameAction,
  signInFailedAction
} from '../actions'
import { initialUserInfoState } from '../reducers/user-info'
import { IStoreState } from '../states'
import { SignInTypeKeys } from '../types'

// Worker Saga
function* fetchSignInAsync() {
  const routerHistory: RouteComponentProps['history'] = yield getContext('routerHistory')

  try {
    yield put(setIsSigningInAction())
    const { username, password } = yield select(
      (state: IStoreState) => state.signIn
    )
    const user: LoginResponse = yield call(AuthenticationService.login, username, password)
    yield put(setUserInfoUsernameAction(username))
    yield put(setUserInfoTokenAction(user.body.login_token))
    yield put(clearSignInFieldsAction())
    yield put(openSignInPopupAction(false))
    yield put(checkSessionAction())
    yield put(setNotificationAction({label: LiteralsService.get('success'), isError: false }))
    yield call(routerHistory.push, urls.dashboard)
  } catch (error) {
    yield put(signInFailedAction(error))
    yield put(setNotificationAction({label: LiteralsService.get('incorrectUsernameAndPassword'), isError: true }))
  }
}

function* signOutAsync() {
  const routerHistory: RouteComponentProps['history'] = yield getContext('routerHistory')

  try {
    const { token } = yield select(
      (state: IStoreState) => state.userInfo
    )
    yield call(routerHistory.replace, urls.home)
    yield put(clearSignInFieldsAction())
    yield put(setUserInfoAction(initialUserInfoState))
    yield call(AuthenticationService.logout, token)
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.log(error)
  }

}

// Watcher Saga:
function* watchFetchSignInAsync() {
  yield takeLatest(SignInTypeKeys.SIGN_IN, fetchSignInAsync)
}

function* watchSignOutAsync() {
  yield takeLatest(SignInTypeKeys.SIGN_OUT, signOutAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchFetchSignInAsync(), watchSignOutAsync()])
}
