import styled from 'styled-components'
import { Color } from '../../models'

interface Props {
  labelColor?: Color
}

export default styled.div<Props>`
  font-family: ${ ({ theme }) => theme.fontFamily };
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
  box-sizing: border-box;
  transition: all 0.2s ease-in;

  label {
    position: absolute;
    background-color: transparent;
    padding: 0;
    top: -26px;
    z-index: 0;
    color: ${ ({ theme, labelColor }) => labelColor ? labelColor : theme.colors.black };
    z-index: 1;
    font-size: 16px;
    transition: all 0.2s ease-in-out;

    @media (max-width: 768px) {
      font-size: 12px;
      letter-spacing: 0px;
    }
  }

  input {
    font-family: ${ ({ theme }) => theme.fontFamily };
    padding: 8px 8px;
    width: 100%;
    font-size: 20px;
    box-sizing: border-box;
    border: none;
    box-shadow:inset 0px 1px 8px rgba(0, 0, 0, 0.6);
    outline: none;
    transition: all 0.1s ease-in-out;

    @media (max-width: 768px) {
      font-size: 14px;
      letter-spacing: 0px;
      line-height: 14px;
    }

    &:hover {
      box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.4);
    }

    &:focus {
      box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.4), 0px 0px 8px rgba(0, 0, 0, 0.2);
    }
  }

  &.filled {
    label {
      color: ${ ({ theme, labelColor }) => labelColor ? labelColor : theme.colors.black };
      /* top: -24px; */
      z-index: 1;
      font-size: 16px;

      @media (max-width: 768px) {
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 14px;
      }
    }
  }

  &.invalid {
    label {
      color: ${ ({ theme }) => theme.colors.red };
    }
    input {
      color: ${ ({ theme }) => theme.colors.red };
      border-bottom-color: ${ ({ theme }) => theme.colors.red };
    }
  }
`
