import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import theme from '../../theme'
import { images } from '../../images'
import { IMenuOption } from '../../models'
import { urls } from '../../routing'
import { LiteralsService } from '../../services'
import { showMobileMenuAction, signOutAction } from '../../store/actions'
import { IStoreState } from '../../store/states'
import { Dropdown, Button } from './../../components'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  username: string
  showMobileMenu: boolean
}

interface IState {
  showLanguageMenu: boolean
}

class NavigationMenu extends PureComponent<IProps, IState> {
  private options: IMenuOption[]
  private languages: IMenuOption[]

  constructor(props: IProps) {
    super(props)
    const { dispatch, history } = props
    this.options = [
      {icon: 'iconFlag', label: LiteralsService.get('language').toUpperCase(), action: () => {
        this.setState({showLanguageMenu: true})
      }, isDisableToggle: true},
      {icon: 'iconSettings', label: LiteralsService.get('settings').toUpperCase(), action: () => {
        history.push(urls.settings)
      }},
      {icon: 'iconLogout', label: LiteralsService.get('logout').toUpperCase(), action: () => {
        dispatch(signOutAction())
      }}
    ]
    this.languages = [
      {icon: 'flagUnitedStates', label: 'English', action: () => {
        this.setState({showLanguageMenu: false})
        LiteralsService.setLanguage('en')
      }},
      {icon: 'flagChina', label: '中文', action: () => {
        this.setState({showLanguageMenu: false})
        LiteralsService.setLanguage('en')
      }}
    ]
    this.state = {
      showLanguageMenu: false
    }
  }

  private get settingOptions(): IMenuOption[] {
    return this.options.map((o) => ({
      icon: o.icon,
      label: o.label,
      isDisableToggle: o.isDisableToggle,
      backgroundColor: 'transparent',
      color: theme.colors.white,
      activeColor: theme.colors.azure,
      action: o.action
    }))
  }

  private get languageOptions(): IMenuOption[] {
    return this.languages.map((o) => ({
      icon: o.icon,
      label: o.label,
      backgroundColor: 'transparent',
      color: theme.colors.white,
      activeColor: theme.colors.azure,
      action: o.action
    }))
  }

  public render(): ReactNode {
    const { username, showMobileMenu } = this.props
    const { showLanguageMenu } = this.state
    return (
      <StyledSection>
        <header>
          <section className="logo">
            <img src={ images['logo'] } alt="supermine-io"/>
            <h2>{LiteralsService.get('supermine').toUpperCase()}</h2>
          </section>
          <section className="logo-mobile">
            <img src={images.iconDashboard} />
            <h3>{LiteralsService.get('dashboard', true)}</h3>
            <button onClick={this.onShowMobileMenu} className="showButton">
              {
                showMobileMenu ?
                <img src={images.iconDashboardArrowUp} /> :
                <img src={images.iconDashboardArrowDown} />
              }
            </button>
          </section>
          <section className="navigation">
            <Dropdown
              options={ showLanguageMenu ? this.languageOptions : this.settingOptions }
              optionStyle={{
                backgroundColor: theme.colors.orient
              }}
              onClosed={() => {if (showLanguageMenu) this.setState({showLanguageMenu: false}) }}
            >
              <p className="menuItem">
                <img src={images.iconUserGreen} style={{}} />
                <span>
                  { username }
                </span>
              </p>
            </Dropdown>
            <Button
              className={'refreshButton'}
              onPress={ this.onRefresh }
              icon={'iconRefresh'}
            />
          </section>
        </header>
      </StyledSection>
    )
  }

  private onRefresh = () => {window.location.reload()}
  private onShowMobileMenu = () => {
    const { dispatch, showMobileMenu } = this.props

    dispatch(showMobileMenuAction(!showMobileMenu))
  }

}

const mapStateToProps = ({ userInfo, dashboard }: IStoreState) => ({
  username: userInfo.username,
  showMobileMenu: dashboard.showMobileMenu
})

export default withRouter(connect(mapStateToProps)(NavigationMenu))
