import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { LiteralsService, SettingService } from '../../services'
import { SettingsResponse } from '../../models'
import {
  getSettingsSuccessfulAction,
  setIsGettingSettingsAction,
  setIsUpdatingSettingsAction,
  setNotificationAction,
  updateSettingsFailedAction,
  updateSettingsSuccessfulAction
} from '../actions'
import { IStoreState } from '../states'
import { SettingsTypeKeys } from '../types'

// Worker Saga
function* getSettingsAsync() {
  try {
    yield put(setIsGettingSettingsAction())
    const { token } = yield select(
      (state: IStoreState) => state.userInfo
    )
    const settings: SettingsResponse = yield call(SettingService.getUserSettings, token)
    yield put(getSettingsSuccessfulAction({...settings, rewardAddress: settings.reward_addr}))
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.log(error)
  }
}

function* updateSettingsAsync() {
  try {
    yield put(setIsUpdatingSettingsAction())
    const { username, rewardAddress, threshold } = yield select(
      (state: IStoreState) => state.setting
    )
    const { token } = yield select(
      (state: IStoreState) => state.userInfo
    )
    yield call(SettingService.updateSettings, token, username, rewardAddress, threshold)
    yield put(updateSettingsSuccessfulAction())
    yield put(setNotificationAction({label: LiteralsService.get('success'), isError: false }))
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.log(error)
    yield put(updateSettingsFailedAction())
    yield put(setNotificationAction({label: LiteralsService.get('reuqestFailed'), isError: true }))
  }
}

// Watcher Saga:
function* watchFetchSignInAsync() {
  yield takeLatest(SettingsTypeKeys.GET_SETTINGS, getSettingsAsync)
}

function* watchSignOutAsync() {
  yield takeLatest(SettingsTypeKeys.UPDATE_SETTINGS, updateSettingsAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchFetchSignInAsync(), watchSignOutAsync()])
}
