import React, { ChangeEvent, CSSProperties, useState } from 'react'
import { Color } from '../../models'
import { Tooltip } from '../Tooltip'
import StyledDiv from './styles'

interface IProps {
  label: string
  labelColor?: Color
  name: string
  value: string
  onChange: (value: string) => void
  error?: boolean
  styles?: CSSProperties
  isPassword?: boolean
  isReadonly?: boolean
  tooltip?: string
}

export const ValidatedInput: React.FC<IProps>
  =  ({ label, name, value, onChange, error, styles, isPassword, isReadonly, tooltip, labelColor }) => {
    const [isFocused, setIsFocused] = useState(false)
    const isInvalid = !isFocused && !!value && !!error
    const classNames = `${ (!isFocused && !value) || 'filled' } ${ isInvalid && 'invalid' }`
    const tooltipContent = !tooltip ? null : <Tooltip>{ tooltip }</Tooltip>

    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange && typeof onChange === 'function') onChange(event.currentTarget.value)
    }

    return (
      <StyledDiv className={ classNames } labelColor={labelColor} >
        <label htmlFor={ name }>{ label }</label>
        <input
          id={ name }
          value={ value }
          style={ styles }
          type={ isPassword ? 'password' : 'text' }
          onFocus={ handleFocus }
          onBlur={ handleBlur }
          onChange={ handleChange }
          readOnly={ isReadonly }
        />
        { tooltipContent }
      </StyledDiv>
    )
  }
