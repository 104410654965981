import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { LiteralsService } from '../../services'
import { images } from '../../images'
import { urls } from '../../routing'
import StyledArticle from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  username: string
  password: string
  isSigningIn: boolean
  error: boolean
}

interface IState {
  count: number
}

class ComingSoon extends PureComponent<IProps, IState> {

  constructor(props: IProps) {
    super(props)
    this.state = {
      count: 1000
    }
  }

  private getRandom(n: number, positive: boolean) {
    if (positive) return Math.ceil(Math.random() * n)
    return Math.ceil(Math.random() * n) * (Math.round(Math.random()) ? 1 : -1)
  }

  public render(): ReactNode {
    return (
      <StyledArticle>
        <div className="stars">
          {
            Array(this.state.count).fill(null).map((v, index) => (
              <div
                key={index.toString()}
                className="container"
                style={{
                  width: '2px',
                  height: '2px',
                  left: `${this.getRandom(100, true)}vw`,
                  top: `${this.getRandom(100, true)}vh`,
                  animationDelay: `-${this.getRandom(1000, true)}s`,
                  animationDuration: `${this.getRandom(1000, true)}s`
                }}
              >
                <div
                  className="star"
                  style={{
                    width: 'inherit',
                    height: 'inherit',
                    animationDelay: `-${this.getRandom(1000, true)}s`,
                    animationDuration: `${this.getRandom(1000, true)}s`,
                    background:
                    `rgba(${this.getRandom(1024, true)},
                      ${this.getRandom(1024, true)},
                      ${this.getRandom(1024, true)},
                      ${(this.getRandom(7, true) + 5) / 10}
                    )`
                  }}
                />
              </div>
            ))
          }
          <div className="header">
            <h2>{LiteralsService.get('comingSoon', true) + '...'}</h2>
            <Link
              to={ urls.home }
              className={'logo'}
            >
              <img src={images.logo} />
            </Link>
          </div>
          <img src={images.comingSoonBear} className="bear" />
        </div>
      </StyledArticle>
    )
  }
}

export default withRouter(connect()(ComingSoon))
