import styled from 'styled-components'

export default styled.section`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 360px;
  /* height: 90px; */
  padding: 8px 16px;

  @media (max-width: 768px) {
    width: 240px;
    margin-top: 2rem;
    border-radius: 24px;
    /* border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; */
  }
  
  .title {
    width: 100%;
    height: 80px;
    border-radius: 12px;
    background-color: ${ ({ theme }) => theme.colors.lightGreen };
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;

    @media (max-width: 768px) {
      border-radius: 24px;
      align-items: center;
    }

    h4 {
      margin: 0;
      text-align: center;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 1pt;
      line-height: 40px;

      @media (max-width: 768px) {
        width: 240px;
        font-size: 20px;
      }
    }
  }

  .details {
    background-color: ${ ({ theme }) => theme.colors.almostGreen };
    width: 300px;
    min-height: 360px;
    justify-content: center;
    padding: 8px;
    box-shadow: 0px 2px 6px 4px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    @media (max-width: 768px) {
      margin-top: -24px;
      width: 200px;
      height: auto;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      padding: 24px 8px;
    }
    
    p {
      margin: 0;
      margin-top: 1rem;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
      white-space: pre-wrap;

      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .bold {
      margin-top: 1rem;
      font-weight: 600;
    }
  }

`
