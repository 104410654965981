import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { DashboardService, LiteralsService } from '../../../services'
import { IStoreState } from '../../../store/states'
import {
  copyFarmerTokenAction,
  createFarmerTokenAction,
  getDashboardAction
  // renamingFarmerTokenAction
} from '../../../store/actions'
import { Button, FarmerCard } from '../../../components'
import { FarmerResponse } from '../../../models'
import theme from '../../../theme'
import { ModalContainer } from '../../../components/FarmerCard/styles'
import { images } from '../../../images'
import { Modal } from '../../../components/Modal'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  username: string
  loginToken: string
  farmers: FarmerResponse[]
  error: boolean
  isLoading: boolean
  isCreatingFarmerToken: boolean
  copiedFarmerToken: string
  selectedCoin: string
}

interface IState {
  deleteToken: string
  isDelete: boolean
  isModal: boolean
  name: string
  selectedFarmerToken: number
  showFarmer: boolean
}


class DashboardFarmers extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      deleteToken: '',
      isDelete: false,
      isModal: false,
      name: '',
      selectedFarmerToken: 0,
      showFarmer: true
    }
  }
  public render(): ReactNode {
    const { isCreatingFarmerToken, farmers, copiedFarmerToken, selectedCoin } = this.props
    return (
      <>
        <StyledSection>
          <header>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h5>{LiteralsService.get('farmers')}</h5>
              <img
                src={!this.state.showFarmer ? images.activeEye : images.eye}
                alt="Eye"
                onClick={this.showFarmersHandler}
              />
            </div>
            <Button
              title={ LiteralsService.get('createFarmerToken') }
              onPress={ this.onCreateToken }
              filled
              upper
              loading={isCreatingFarmerToken}
              styles={{
                width: '174px',
                height: '24px',
                padding: '4px 9px',
                fontSize: '12px',
                color: theme.colors.white,
                backgroundColor: theme.colors.azure
              }}
            />
          </header>
          <div>
            <FarmerCard
              isTitle
              farmer={{
                name: LiteralsService.get('name').toUpperCase(),
                token: LiteralsService.get('token').toUpperCase(),
                plotCount: LiteralsService.get('plotCount').toUpperCase(),
                lastUpdate: LiteralsService.get('lastUpdate').toUpperCase()
              }}
            />
            {this.state.showFarmer ?
              farmers.map((f, i) => (
                <FarmerCard
                  key={i.toString()}
                  farmer={{
                    name: f.name,
                    token: f.token,
                    plotCount: f[selectedCoin === 'xch' ? 'xch_plot_count' : 'hdd_plot_count'],
                    lastUpdate: f.last_update,
                    isOnline: false
                  }}
                  onClick={() => this.onChangeFarmerTokenName(f)}
                  copied={copiedFarmerToken===f.token}
                  onClickCopy={() => this.onCopyFarmertoken(f.token)}
                  onClickEdit={() => this.modalOpenHandler(i)}
                  onClickDelete={() => this.onDeleteFarmertoken(f.token)}
                />
              ))
           :
           farmers.map((f, i) => (
            <FarmerCard
              key={i.toString()}
              farmer={{
                name: '******',
                token: '******',
                plotCount: '******',
                lastUpdate: '********',
                isOnline: false
              }}
              onClick={() => this.onChangeFarmerTokenName(f)}
              copied={copiedFarmerToken===f.token}
              onClickCopy={() => this.onCopyFarmertoken(f.token)}
              onClickEdit={() => this.modalOpenHandler(i)}
              onClickDelete={() => this.onDeleteFarmertoken(f.token)}
            />
          ))
           
           }
          </div>
          <Modal
            isHidden={!this.state.isModal}
            style={{
              width: 604,
              padding: 0,
              background: '#223149',
              overflow: 'hidden',
              border: '2px solid #28F19D',
              boxSizing: 'border-box',
              borderRadius: 20
            }}
          >
            <ModalContainer>
                <img
                  src={images.goBack}
                  className="goBackIconEdit"
                  alt="Go Back Icon"
                  onClick={this.modalCloseHandler}
                />
              <p className='txt'>Change Token Name</p>
              <div className="renameSection">
                <input
                  type="text"
                  onChange={this.onTokenNameChange}
                  className='tNameInput'
                />
                <button
                  className='confirmBtn'
                  type="button"
                  onClick={this.confirmHandler}
                >
                  Confirm
              </button>
              </div>
            </ModalContainer>
          </Modal>
        </StyledSection>
      </>
    )
  }

  private showFarmersHandler = () => {
    this.setState({
      showFarmer: !this.state.showFarmer
    })
  }

  private onTokenNameChange = (e: any) => {
    this.setState({
      name: e.currentTarget.value
    })
  }

  private modalOpenHandler = (i: number) => {
    this.setState({
      isModal: true,
      selectedFarmerToken: i
    })
  }

  private modalCloseHandler = () => {
    this.setState({
      isModal: false
    })
  }

  // private confirmHandler = () => {
  //   this.props.dispatch(renamingFarmerTokenAction({
  //     name: this.state.name,
  //     token: this.props.farmers[this.state.selectedFarmerToken].token
  //   }))
  //   this.setState({
  //     isModal: false
  //   })
  // }

  private confirmHandler = async () => {
    // this.props.dispatch(renamingFarmerTokenAction({
    //   name: this.state.name,
    //   token: this.props.farmers[this.state.selectedFarmerToken].token
    // }))
    this.setState({
      isModal: false
    })
    try {
      const response = await DashboardService.renameFarmerToken(
        this.props.loginToken,
        this.state.name,
        this.props.farmers[this.state.selectedFarmerToken].token
      )
      if (response) this.props.dispatch(getDashboardAction())
    } catch (error) {
      console.log(error)
    }
  }

  private onCreateToken = () => {
    this.props.dispatch(createFarmerTokenAction())
  }

  private onChangeFarmerTokenName = (farmer: FarmerResponse) => {
    console.log(farmer)
  }

  private onCopyFarmertoken = (token: string) => {
    navigator.clipboard.writeText(token)
    this.props.dispatch(copyFarmerTokenAction(token))
  }

  private onDeleteFarmertoken = async (token: string) => {
    const { loginToken } = this.props
    try {
      const response = await DashboardService.deleteFarmerToken(loginToken, token)
      if (response) this.props.dispatch(getDashboardAction())
    } catch (error) {
      console.log(error)
    }
  }
}

const mapStateToProps = ({ userInfo, dashboard }: IStoreState) => ({
  username: userInfo.username,
  loginToken: userInfo.token,
  farmers: dashboard.farmers,
  error: dashboard.error,
  isLoading: dashboard.isLoading,
  isCreatingFarmerToken: dashboard.isCreatingFarmerToken,
  copiedFarmerToken: dashboard.copiedFarmerToken
})

export default withRouter(connect(mapStateToProps)(DashboardFarmers))
