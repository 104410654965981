/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DashboardResponse } from '../../../models'
import { LiteralsService } from '../../../services'
import { IStoreState } from '../../../store/states'
import { DashboardCard } from '../../../components'
import { getDashboardAction } from '../../../store/actions'
import { Modal } from '../../../components/Modal'
import Checkbox from '../../../components/Checkbox'
import { getFiltersFromProps } from '../../../utils/getData'
import { images } from '../../../images'

import { FilterDiv, CoinOptions, StyledDiv, ModalContainer } from './styles'

export interface FiltersType {
  [key: string]: string | number
}

export interface KeyOfCoinType {
  [key: string]: string[]
}

export interface CheckedStateType {
  [key: string]: any[]
}

export type SelectedCoinType =
  | 'xchExpectedEarningNextBlock'
  | 'hdd_price_usd'
  | 'total_netspace'
  | 'total_pooled_space'
  | 'number_of_farmers'
  | 'pending_balance'
  | 'lifetime_balance'
  | 'expected_earnings_next_block'
  | 'your_pooled_plot_space'
  | 'hdd_per_day'
  | 'hdd_per_week'
  | 'hdd_per_month'
  | 'usd_per_day'
  | 'usd_per_week'
  | 'usd_per_month'
  | 'xch_price_usd'
  | 'xch_per_day'
  | 'xch_per_week'
  | 'xch_per_month'

const btns = [
  { id: 1, img: images.chiaLogo, click: 'xch', alt: 'Chia Logo' ,class: 'chia-logo' },
  { id: 2, img: images.hddLogo, click: 'hdd', alt: 'HDD logo' ,class: 'hdd-logo' }
]

const DashboardCards = ({ selectCoinHandler }: DashboardCardsProps) => {
  const [value, setValue] = useState(1)
  const getFilters = localStorage['filters']
    ? JSON.parse(localStorage['filters'])
    : {
        xch: [],
        hdd: []
      }

  const dispatch = useDispatch()
  const dashboard = useSelector((state: IStoreState) => state.dashboard)

  const [isModal, setIsModal] = useState(false)
  const [selectedCoin, setSelectedCoin] = useState('xch')
  const [selectedId, setSelectedId] = useState(-1)

  const [keysOfSelectedCoin, setKeysOfSelectedCoin] = useState<KeyOfCoinType>({
    xch: Object.keys(dashboard.xch),
    hdd: Object.keys(dashboard.hdd)
  })

  const getCheckedState = localStorage['checkedState']
    ? JSON.parse(localStorage['checkedState'])
    : {
        xch: new Array(keysOfSelectedCoin.xch.length).fill(false),
        hdd: new Array(keysOfSelectedCoin.hdd.length).fill(false)
      }
  const [checkedState, setCheckedState] = useState<CheckedStateType>(getCheckedState)

  const [newKeysOfSelectedCoin, setNewKeysOfSelectedCoin] = useState<string[]>(getFilters[selectedCoin])

  const [defaultFilters, setDefaultFilters] = useState<FiltersType>(dashboard?.xch)

  useEffect(() => {
    dispatch(getDashboardAction())

    const interval = setInterval(() => {
      dispatch(getDashboardAction())
    }, 2 * 60 * 1000)

    return () => clearInterval(interval)
  }, [dispatch])

  useEffect(() => {
    setDefaultFilters(selectedCoin === 'xch' ? dashboard.xch : dashboard.hdd)
    setNewKeysOfSelectedCoin(getFilters[selectedCoin])
  }, [dashboard, selectedCoin])

  const filterHandler = useCallback(() => {
    setIsModal(true)
  }, [])

  const modalCloseHandler = useCallback(() => {
    setIsModal(false)
  }, [])

  const checkboxHandler = useCallback(
    (target, idx) => {
      setSelectedId(idx)
      const updatedCheckedState = checkedState[selectedCoin].map((item, index) =>
        index === idx
          ? item === false
            ? (setNewKeysOfSelectedCoin([...newKeysOfSelectedCoin, target.name]),
              (localStorage['filters'] = JSON.stringify({
                ...getFilters,
                [selectedCoin]: [...newKeysOfSelectedCoin, target.name]
              })),
              !item)
            : (setNewKeysOfSelectedCoin(newKeysOfSelectedCoin.filter((ele) => ele !== target.name)),
              (localStorage['filters'] = JSON.stringify({
                ...getFilters,
                [selectedCoin]: newKeysOfSelectedCoin.filter((ele) => ele !== target.name)
              })),
              !item)
          : item
      )
      setCheckedState({
        ...checkedState,
        [selectedCoin]: updatedCheckedState
      })
      localStorage['checkedState'] = JSON.stringify({
        ...checkedState,
        [selectedCoin]: updatedCheckedState
      })
    },
    [checkedState, newKeysOfSelectedCoin, selectedCoin]
  )

  const coinSelector = useCallback(
    (coin) => {
      setSelectedCoin(coin)

      const coinKey: keyof DashboardResponse = coin
      setKeysOfSelectedCoin({
        ...keysOfSelectedCoin,
        [selectedCoin]: Object.keys(dashboard[coinKey])
      })
      setDefaultFilters(dashboard[coinKey])

      selectCoinHandler(coin)
    },
    [dashboard]
  )

  const ModalContent = () => (
    <ModalContainer>
      <img
        src={images.goBack}
        className="goBackIcon"
        alt="Go Back Icon"
        onClick={modalCloseHandler}
      />
      <div className="checkboxGroup">
        {keysOfSelectedCoin[selectedCoin].map((coinKey, idx) => {
          const coinKeyVal: SelectedCoinType = coinKey as SelectedCoinType
          return (
            <Checkbox
              key={coinKey}
              id={coinKey}
              label={LiteralsService.get(coinKeyVal)}
              name={coinKey}
              active={selectedId === idx}
              checked={checkedState[selectedCoin][idx]}
              onChange={({ target }) => checkboxHandler(target, idx)}
            />
          )
        })}
      </div>
    </ModalContainer>
  )

  return (
    <div>
      <FilterDiv>
        <div className="filterBtn" onClick={filterHandler}>
          <p>{LiteralsService.get('filter').toUpperCase()}</p>
          <img src={images.iconMenu} />
        </div>
      </FilterDiv>
      <div style={{ display: 'flex' }}>
        <CoinOptions>
          {btns.map((btn) => (
            <div
              key={btn.id}
              className={`toggle-btn ${btn.class} ${btn.id === value && 'toggle-active'}`}
              onClick={() => {
                coinSelector(btn.click)
                setValue(btn.id)
              }}
            >
              <img src={btn.img} className="coinLogo" alt={btn.alt} />
            </div>
          ))}
        </CoinOptions>
        <StyledDiv>
          <div className="cardGroup">
            {newKeysOfSelectedCoin.length > 0
              ? newKeysOfSelectedCoin.map((coin, index) => {
                  const coinVal: SelectedCoinType = coin as SelectedCoinType
                  return (
                    <DashboardCard
                      key={index.toString()}
                      title={LiteralsService.get(coinVal)}
                      value={getFiltersFromProps({
                        label: coinVal,
                        value: defaultFilters[coin],
                        selectedCoin
                      })}
                    />
                  )
                })
              : keysOfSelectedCoin[selectedCoin].map((coin, index) => {
                  const coinVal: SelectedCoinType = coin as SelectedCoinType
                  return (
                    <DashboardCard
                      key={index.toString()}
                      title={LiteralsService.get(coinVal)}
                      value={getFiltersFromProps({
                        label: coinVal,
                        value: defaultFilters[coin],
                        selectedCoin
                      })}
                    />
                  )
                })
            }
          </div>
        </StyledDiv>
      </div>
      <Modal
        isHidden={!isModal}
        style={{
          width: 317,
          height: 517,
          padding: 0,
          background: 'rgb(34, 49, 73)',
          overflow: 'hidden',
          border: '2px solid #28F19C',
          boxSizing: 'border-box',
          borderRadius: 19
        }}
      >
        <ModalContent />
      </Modal>
    </div>
  )
}

export default DashboardCards

export interface DashboardCardsProps {
  selectCoinHandler: (coin: string) => void
}
