const lowercaseRegExp = /[a-z]/
const uppercaseRegExp = /[A-Z]/
const digitsRegExp = /[0-9]/
const specialCharactersRegExp = /[\^$*.[]{}()?-"!@#%&\/\\,><':;|_~`]/
const otherCharsRegExp = /[=+]/
const lengthRegExp = /^.{8,}$/

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function hasTelephonePrefix(telephone: string): boolean {
  const regExp = new RegExp('^\\+[0-9]{2,2}')
  return regExp.test(telephone)
}

export function sanitizeTelephone(telephone: string): string {
  return telephone && !hasTelephonePrefix(telephone)
    ? `+34${telephone}`
    : telephone || ''
}

export function isEmailValid(email: string): boolean {
  const regExp = new RegExp('[a-zA-Z0-9]+@[a-zA-Z]+[.][a-zA-Z]{2,3}')
  return regExp.test(email)
}

export function isUsernameValid(username: string): boolean {
  const regExp = new RegExp('^(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$')
  return regExp.test(username)
}

export function isCodiceValid(codice: string): boolean {
  // eslint-disable-next-line max-len
  const regExp = new RegExp('^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})|([0-9]{11})$')
  return regExp.test(codice)
}

export function isPasswordValid(password: string): boolean {
  let isValid = true

  if (!lowercaseRegExp.test(password)) isValid = false
  // if (!uppercaseRegExp.test(password)) isValid = false
  if (!digitsRegExp.test(password)) isValid = false
  // if (!specialCharactersRegExp.test(password)) isValid = false
  if (!lengthRegExp.test(password)) isValid = false
  if (!Array.from(password).every(isCharValid)) isValid = false

  return isValid
}

function isCharValid(character: string): boolean {
  return (
    lowercaseRegExp.test(character) ||
    uppercaseRegExp.test(character) ||
    digitsRegExp.test(character) ||
    specialCharactersRegExp.test(character) ||
    otherCharsRegExp.test(character)
  )
}

export function isTextValid(text: string): boolean {
  const regExp = new RegExp(
    '^[a-zA-ZáàäéèëíìïóòöúùüÁÀÄÉÈËIÍÌÏÓÒÖÚÙÜñçÑÇ ]{3,}$'
  )
  return regExp.test(text)
}

export function isTelephoneValid(telephone: string): boolean {
  const regExp = new RegExp('^(\\+?[0-9]{1,3})?[0-9]{9}$')
  return regExp.test(telephone)
}

export function isVerificationCodeValid(code: string): boolean {
  const regExp = new RegExp('^[0-9]{6}$')
  return regExp.test(code)
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
