import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { DashboardService, LiteralsService, SettingService } from '../../services'
import { CreateFarmerResponse, DashboardResponse, FarmerResponse } from '../../models'
import {
  setIsUpdatingSettingsAction,
  setNotificationAction,
  gettingDashboardAction,
  updateSettingsFailedAction,
  updateSettingsSuccessfulAction,
  getDashboardSuccessfulAction,
  creatingFarmerAction,
  createdFarmerTokenSuccessfullyAction
  // renameFarmerTokenAction
} from '../actions'
import { IStoreState } from '../states'
import { DashboardTypeKeys } from '../types'

// Worker Saga
function* getDashboardAsync() {
  try {
    yield put(gettingDashboardAction(true))
    const { token } = yield select((state: IStoreState) => state.userInfo)

    const response: DashboardResponse = yield call(DashboardService.getDashboard, token)

    const farmers: FarmerResponse[] = Object.keys(response.farmers).map((key: string) => {
      return {
        ...response.farmers[key],
        token: key
      }
    })

    yield put(
      getDashboardSuccessfulAction({
        farmers,
        hdd: response.hdd,
        xch: response.xch
      })
    )
    // yield put(setNotificationAction({label: LiteralsService.get('success'), isError: false }))
  } catch (error) {
    // yield put(setNotificationAction({label: LiteralsService.get('reuqestFailed'), isError: true }))
    yield put(gettingDashboardAction(false))
  }
}

function* updateDashboardAsync() {
  try {
    yield put(setIsUpdatingSettingsAction())
    const { username, rewardAddress, threshold } = yield select((state: IStoreState) => state.setting)
    const { token } = yield select((state: IStoreState) => state.userInfo)
    yield call(SettingService.updateSettings, token, username, rewardAddress, threshold)
    yield put(updateSettingsSuccessfulAction())
  } catch (error) {
    // tslint:disable-next-line: no-console
    yield put(updateSettingsFailedAction())
  }
}

function* createFarmerTokenAsync() {
  try {
    yield put(creatingFarmerAction(true))
    const { token } = yield select((state: IStoreState) => state.userInfo)
    const response: CreateFarmerResponse = yield call(DashboardService.createFarmerToken, token, 'Token Name')
    /* eslint-disable camelcase */
    const farmer: FarmerResponse = {
      token: response.body.farmer_token,
      name: 'My Farmer Token',
      xch_plot_count: 'No plots',
      hdd_plot_count: 'No plots',
      last_update: 'Never Started.'
    }
    yield put(createdFarmerTokenSuccessfullyAction(farmer))
    yield put(setNotificationAction({ label: LiteralsService.get('success'), isError: false }))
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.log(error)
    yield put(setNotificationAction({ label: LiteralsService.get('reuqestFailed'), isError: true }))
    yield put(creatingFarmerAction(false))
  }
}

// function* renameFarmerTokenAsync() {
//   try {
//     yield put(renameFarmerTokenAction(true))
//     const { token } = yield select((state: IStoreState) => state.userInfo)
//     const { renameFarmer } = yield select((state: IStoreState) => state.dashboard)
//     const response1: string = yield call(
//       DashboardService.renameFarmerToken, token, renameFarmer.name, renameFarmer.token
//     )
//     console.log('-----------')
//     console.log(response1)

//     const response: DashboardResponse = yield call(DashboardService.getDashboard, token)
//     const farmers: FarmerResponse[] = Object.keys(response.farmers).map((key: string) => {
//       return {
//         ...response.farmers[key],
//         token: key
//       }
//     })

//     yield put(
//       getDashboardSuccessfulAction({
//         farmers,
//         hdd: response.hdd,
//         xch: response.xch
//       })
//     )
//   } catch (error) {
//     yield put(renameFarmerTokenAction(false))
//   }
// }

// Watcher Saga:
function* watchGetDashboardAsync() {
  yield takeLatest(DashboardTypeKeys.GET_DASHBOARD, getDashboardAsync)
}

function* watchUpdateDashboardAsync() {
  yield takeLatest(DashboardTypeKeys.UPDATE_DASHBOARD, updateDashboardAsync)
}

function* watchCreateFarmerTokenAsync() {
  yield takeLatest(DashboardTypeKeys.CREATE_FARMER_TOKEN, createFarmerTokenAsync)
}

// function* watchRenameFarmerTokenAsync() {
//   yield takeLatest(DashboardTypeKeys.GET_DASHBOARD, renameFarmerTokenAsync)
// }

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchGetDashboardAsync(),
    watchUpdateDashboardAsync(),
    watchCreateFarmerTokenAsync()
  ])
}
