import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 22px 0 118px;
  flex: 1;
  background-color: ${ ({ theme }) => theme.colors.mirage };

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 55px;
    margin-bottom: 14px;

    @media (max-width: 768px) {
      display: none;
    }

    img {
      width: 48px;
      height: 48px;
      margin-right: 10px;
    }

    h3 {
      color: ${ ({ theme }) => theme.colors.greenSpring };
      font-family: Montserrat;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin: 0;
    }
  }

  .chia{
    width:500px;
    height:500px;
  }

`
