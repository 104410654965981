import { Language } from './literals-service'

class StorageService {
  private prefix = '@supermine'
  private keys = {
    language: `${this.prefix}:language`,
    token: `${this.prefix}:token`,
    username: `${this.prefix}:username`
  }

  public setUsername = (token: string): void => {
    return this.setByKey(this.keys.username, token)
  }

  public getUsername = (): string => {
    return this.getByKey(this.keys.username)
  }

  public removeUsername = (): void => {
    return this.removeByKey(this.keys.username)
  }

  public setToken = (token: string): void => {
    return this.setByKey(this.keys.token, token)
  }

  public getToken = (): string => {
    return this.getByKey(this.keys.token)
  }

  public removeToken = (): void => {
    return this.removeByKey(this.keys.token)
  }

  public setLanguage = (language: Language): void => {
    return this.setByKey(this.keys.language, language)
  }

  public getLanguage = (): Language => {
    return this.getByKey(this.keys.language)
  }

  public removeLanguage = (): void => {
    return this.removeByKey(this.keys.language)
  }

  private getByKey = (key: string): any => {
    try {
      const value = localStorage.getItem(key)
      return value ? JSON.parse(value) : null
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error)
    }
  }

  private setByKey = (key: string, value: any): void => {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error)
    }
  }

  private removeByKey = (key: string): any => {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error)
    }
  }
}

export default new StorageService()
