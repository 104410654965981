import styled from 'styled-components'

export const ImagePolar =  styled.div`
  position: absolute;
  top: 35vh;
  left: 26vw;
  right: -58vw;
  z-index: 0;

  img {
    display: block;
    min-width: 130vw;
    width: 2400px;
    height: auto;
    overflow: hidden;
    box-sizing: border-box;
  }

  .mobile-background {
    display: none;
  }

  @media (max-width: 768px) {
    img {
      display: none;
    }
  }
`

export const ImageClouds =  styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 0;

  img {
    display: block;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

export const ImageMobileBackground =  styled.div`
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 0;

  img {
    display: block;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    object-fit: cover;
    object-position: center;
    opacity: 15%;
  }
  @media (max-width: 768px) {
    display: block;
  }

`
