export interface PropsType {
  label: string
  value: number | string
  selectedCoin: string
}

export const getFiltersFromProps = ({ label, value, selectedCoin }: PropsType) => {
  console.log(label)
  console.log(value)
  console.log(selectedCoin)
  const strVal = value.toString()
  switch (label) {
    case 'hdd_price_usd':
    case 'hdd_per_day':
    case 'hdd_per_week':
    case 'hdd_per_month':
      return [Number(strVal).toFixed(5), 'HDD']

    case 'xch_price_usd':
    case 'xch_per_day':
    case 'xch_per_week':
    case 'xch_per_month':
      return [Number(strVal).toFixed(5), 'XCH']

    case 'usd_per_day':
    case 'usd_per_week':
    case 'usd_per_month':
      return [Number(strVal).toFixed(2), 'USD']

    case 'total_netspace':
    case 'total_pooled_space':
    case 'your_pooled_plot_space':
      return strVal.split(' ')

    case 'pending_balance':
    case 'lifetime_balance':
    case 'expected_earnings_next_block':
      return [Number(strVal).toFixed(5), selectedCoin === 'xch' ? 'XCH' : 'HDD']

    case 'number_of_farmers':
      return [Number(strVal), '']

    case 'default':
      return ['', '']
  }
}
