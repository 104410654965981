import React, { CSSProperties } from 'react'
import StyledCard from './styles'

interface IProps {
  title: string
  value?: (string | number)[]
  styles?: CSSProperties
  className?: string
}

export const DashboardCard: React.FC<IProps> = ({
  title,
  styles,
  className,
  value
}) => {
  const classNames = `${ className || '' }`
  return (
    <StyledCard
      className={ classNames }
      style={ styles }
    >
      <p className="cardTitle">
        {title}
      </p>
      <div style={{ display: 'flex', position: 'absolute', right: '7px', bottom: '10px' }}>
        <div className="cardValue">
          {value && value[0]}
        </div>
        <div className="cardValue unit">
          {value && value[1]}
        </div>
      </div>
    </StyledCard>
  )
}
